import React from 'react';
import { Row, Col, Image } from 'antd';
import title_glimpse from "./img/SVG/title_glimpse.svg";
import slider1 from "./img/slider_3.gif";
import Citation from "./Citation.jsx";
import { PlusOutlined } from "@ant-design/icons";

// displays the team

function maskShowroom(title, description) {
    return (
        <div>
            <p className="img-mask-title">
            "{title}"
            </p>
            <p className="img-mask-description">
                {description}
            </p>
        </div>
    )
}

export default function WitchesShowroom(props) {
    return (
        <div id="witches-showroom">
            <div className="container">
            <p>&nbsp;</p>
            <Image src={title_glimpse} height={"170px"} preview={false}/>
            <div id="showroom-gallery">
                <Citation 
                    citation={"Sing, feast, dance, make music and love, all in My Presence, for Mine is the ecstasy of the spirit and Mine also is joy on earth."}
                    author="The charge of the Goddess"
                />
                <p>
                    <span className="emphasis">Zoey</span> talks about her art, her process, her inspiration, and the symbolism behind her new collection in her <a href="https://medium.com/@NatureOracle/witches-oracle-nft-ama-with-the-artist-zoey-e929cd96eabc" target="_blank">AMA on Medium.</a>
                </p>
                <p>
                    Real art will be high-resolution. Below are some low-resolution sneak peeks into the art of the <span className="emphasis">Witches Oracle.</span>
                </p>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_21_complete_small.jpg"} preview={{ mask: maskShowroom('Selene', 'A Greek Goddess of the Moon, Selene was said to spend her time driving a Moon Chariot led by white horses across the sky - representing the changing shapes of the Moon'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_22c_complete_small.jpg"} preview={{ mask: maskShowroom('The Ayahuasquera', 'If the doors of perception were cleansed everything would appear to man as it is, infinite - William Blake'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_Body7a_complete_small.jpg"} preview={{ mask: maskShowroom('Inanna', 'The poem/hymn/story "The Descent of Inanna" is though to have been composed at some point between 3500BC and 2000BC'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_Body8a_complete_small.jpg"} preview={{ mask: maskShowroom('The Volva', 'In ancient Norse cultures, men waged wars and women weaved fate, as magic and divination were practiced by women. Volva means " Seeress" in ancient Norse'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_Body12.jpg"} preview={{ mask: maskShowroom('The Starry Goddess', 'We come spinning out of nothingness, scattering stars like dust - Rumi'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_Body25.jpg"} preview={{ mask: maskShowroom('Nut - Goddess of the night sky', 'I am the stars and the spaces between the stars - Anne Corlett'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    );
  }
  