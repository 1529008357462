import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Image } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender, isImg } from './utils';
import title_team from "./img/SVG/title_team.svg";
import Citation from "./Citation.jsx";

// displays the team

export default function TeamDisplay(props1) {

    const getBlockChildren = (data) =>
    data.map((item, i) => {
      const { titleWrapper, ...$item } = item;
      return (
        <Col key={i.toString()} {...$item}>
          {titleWrapper.children.map(getChildrenToRender)}
        </Col>
      );
    });

    const { ...props } = props1;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const listChildren = getBlockChildren(dataSource.block.children);

    return (
        <div {...props} {...dataSource.wrapper}>
          <Image id="team_section" src={title_team} height={"170px"} preview={false}/>
          <Citation 
            citation={"Dance beneath the stars as you drink in the night,\n Let the thunder overtake you as lightnings fills the sky."}
            author="Christy Ann Martine"
          />
          <div {...dataSource.page}>
  {/*           <div {...dataSource.titleWrapper}>
              {dataSource.titleWrapper.children.map(getChildrenToRender)}
            </div>
  */}          <OverPack {...dataSource.OverPack}>
              <QueueAnim
                type="bottom"
                key="block"
                leaveReverse
                {...dataSource.block}
                component={Row}
              >
                {listChildren}
              </QueueAnim>
            </OverPack>
          </div>
      </div>
    );
  }
  