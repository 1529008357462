import React from 'react';
import { Row, Col, Image } from 'antd';
import title_glimpse from "./img/SVG/title_glimpse.svg";
import slider1 from "./img/slider_3.gif";
import Citation from "./Citation.jsx";
import { PlusOutlined } from "@ant-design/icons";

// displays the team

function maskShowroom(title, description) {
    return (
        <div>
            <p className="img-mask-title">
            "{title}"
            </p>
            <p className="img-mask-description">
                {description}
            </p>
        </div>
    )
}

export default function Showroom(props) {
    return (
        <div id="showroom">
            <div className="container">
            <p>&nbsp;</p>
            <Image src={title_glimpse} height={"170px"} preview={false}/>
            <div id="showroom-gallery">
                <Citation 
                    citation={"There is something infinitely healing in the repeated refrains of nature \n The assurance that dawn comes after night, and spring after winter"}
                    author=""
                />
                <p>
                    Real art is high-resolution. Below are some low-resolution sneak peeks into the art of the <span className="emphasis">Nature and Witches Oracle</span> collections.
                </p>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_6b_Earth.jpg"} preview={{ mask: maskShowroom('Hecate - Witches Oracle', 'Hecate was seen as a Goddess of the witches'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/Follow_Your_Dreams_1000.jpg"} preview={{ mask: maskShowroom('Follow your dreams - Nature Oracle', 'Rise above the clouds: what do you see?'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/Water_Of_Life_1000.jpg"} preview={{ mask: maskShowroom('Water of life - Nature Oracle', 'Nourishing and rejuvenating'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_Body18b.jpg"} preview={{ mask: maskShowroom('Descent into the Underworld - Witches Oracle', 'I am the one who has been hated everywhere and who has been loved everywhere. I am the one whom they call Life, and you have called Death'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/0WO_Body14a.jpg"} preview={{ mask: maskShowroom('Asherah - Witches Oracle', 'Asherah is an ancient Mother goddess associated with the early Hebrews. She may have been of Canaanite origin...'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/Voices_Of_Nature_1000.jpg"} preview={{ mask: maskShowroom('Voices of Nature - Nature Oracle', 'Listening to the whispers of the forests'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    );
  }
  