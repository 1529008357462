import React from 'react';
import { Row, Col, Image } from 'antd';
import title_glimpse from "./img/SVG/title_glimpse.svg";
import slider1 from "./img/slider_3.gif";
import Citation from "./Citation.jsx";
import { PlusOutlined } from "@ant-design/icons";

// displays the team

function maskShowroom(title, description) {
    return (
        <div>
            <p className="img-mask-title">
            "{title}"
            </p>
            <p className="img-mask-description">
                {description}
            </p>
        </div>
    )
}

export default function Showroom(props) {
    return (
        <div id="showroom">
            <div className="container">
            <p>&nbsp;</p>
            <Image src={title_glimpse} height={"170px"} preview={false}/>
            <div id="showroom-gallery">
                <Citation 
                    citation={"There is something infinitely healing in the repeated refrains of nature \n The assurance that dawn comes after night, and spring after winter"}
                    author=""
                />
                <p>
                    Real art will be high-resolution. Below are some low-resolution sneak peeks into the art of the <span className="emphasis">Nature Oracle.</span>
                </p>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/Deep_Unconsciousness_1000.jpg"} preview={{ mask: maskShowroom('Deep unconsciousness', 'Immerse in the deep water cave'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/Follow_Your_Dreams_1000.jpg"} preview={{ mask: maskShowroom('Follow your dreams', 'Rise above the clouds: what do you see?'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/Spark_1000.jpg"} preview={{ mask: maskShowroom('Clarity', 'Inner spark of creativity'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/Surrendering_1000.jpg"} preview={{ mask: maskShowroom('Magical Stream', 'Going with waves and flow'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={24}>
                    <Image src={"img/Water_Of_Life_1000.jpg"} preview={{ mask: maskShowroom('Water of life', 'Nourishing and rejuvenating'), maskClassName: "img-mask-style"}}/>
                    </Col>
                    <Col md={12} sm={24}>
                    <Image src={"img/Voices_Of_Nature_1000.jpg"} preview={{ mask: maskShowroom('Voices of nature', 'Listening to the whispers of the forests'), maskClassName: "img-mask-style"}}/>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    );
  }
  