import { Card, InputNumber, Space } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import { useContractLoader } from "../hooks";
import { Transactor } from "../helpers";
import { useParams } from "react-router-dom";
import { Row, Col, Image } from 'antd';
// to change
import title_glimpse from "./img/SVG/title_reading.svg";
import Citation from "./Citation.jsx";
import { DEFAULT_CONTRACT_NAME, MAX_MINTABLE_NFT } from "../constants";
import { dictNatureOraclePrediction } from './listnft';

const DEBUG = true;

// rewrite ipfs:// uris to dweb.link gateway URLs
function makeGatewayURL(ipfsURI) {
  //return ipfsURI.replace(/^ipfs:\/\//, "https://dweb.link/ipfs/");
  //return ipfsURI.replace(/^ipfs:\/\//, "https://natureoracle.mypinata.cloud/ipfs/")
  return ipfsURI.replace(/^ipfs:\/\//, "https://natureoracle.mypinata.cloud/ipfs/").replace(/^https:\/\/gateway.pinata.cloud\/ipfs\//, "https://natureoracle.mypinata.cloud/ipfs/")
  //return ipfsURI.replace(/^ipfs:\/\//, "https://gateway.pinata.cloud/ipfs/")
}

async function fetchIPFSJSON(ipfsURI) {
  const url = makeGatewayURL(ipfsURI);
  const resp = await fetch(url);
  if (resp.ok) {
    return resp.json();
  }
  else {
      return undefined;
  }
}

async function getNFT({contract, provider, tokenId}) {
  const metadataURI = await contract.tokenURI(tokenId);
  if (DEBUG) console.log('metadata uri: ', metadataURI);
  
  const metadata = await fetchIPFSJSON(metadataURI);
  if (DEBUG) console.log('metadata: ', metadata)

  if (metadata) {
    if (metadata.image) {
      metadata.image = makeGatewayURL(metadata.image);
    }

    console.log(metadata.card_fullname);

    if (metadata.card_fullname) {
        metadata.rarity = dictNatureOraclePrediction[metadata.card_fullname].rarity;
        metadata.name = dictNatureOraclePrediction[metadata.card_fullname].name;
        metadata.prediction = dictNatureOraclePrediction[metadata.card_fullname].prediction;
    }
  }
  
  return metadata;
}

function romanize(num) {
    var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},roman = '',i;
    for ( i in lookup ) {
      while ( num >= lookup[i] ) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
}

async function getBalanceOfNFT({contract, ownerAddress, provider, gasPrice}) {

    // scaffold-eth's Transactor helper gives us a nice UI popup when a transaction is sent
    //const transactor = Transactor(provider, gasPrice);
      
    // Call the 
    //const nftbalance = await transactor(contract.balanceOf(ownerAddress));
    const balance = await contract.balanceOf(ownerAddress);
    //console.log('nft balance', nftbalance);
    return balance;
}
  
// function NFTCard({
//   contract,
//   provider,
//   tokenId,
//   nftData,
// }) {
//   return (
//     <Card>
//       <img className="nftgallery-img" src={nftData.image} style={{maxHeight: "800px"}}/>
//       <div>
//         Name: {nftData.name}
//       </div>
//       <div>
//         Description: {nftData.description}
//       </div>
//     </Card>    
//   );
// }
function maskShowroom(title, romannumber) {
    return (
        <div>
            <p className="img-mask-title">
            "{title}"
            </p>
            <p className="img-mask-description">
                {romannumber}
            </p>
        </div>
    )
}

function NFTCard({
    tokenId,
    nftData,
  }) {
    if (DEBUG) console.log("tokenID", tokenId);
    if (DEBUG) console.log("attributes", nftData.attributes);

    let attributeDiv = "";
    if ('attributes' in nftData) {
        attributeDiv = <div>
                        <div className="nftgallery-label">
                            Card {nftData.attributes[0].trait_type}
                        </div>
                        <div className="nftgallery-content">
                            {nftData.attributes[0].value}
                        </div>
                      </div>
    }
    else if ('rarity' in nftData) {
        attributeDiv = <div>
                        <div className="nftgallery-label">
                            Card Rarity
                        </div>
                        <div className="nftgallery-content">
                            {nftData.rarity}
                        </div>
                      </div>
    }

    let predictionDiv = "";
    if (nftData.prediction || (('card_fullname' in nftData) && (nftData.card_fullname in dictNatureOraclePrediction) && ('prediction' in dictNatureOraclePrediction[nftData.card_fullname]))) {
        predictionDiv = <div>
                            <div className="nftgallery-label">
                                Prediction
                            </div>
                            <div className="nftgallery-content">
                                {nftData.prediction || dictNatureOraclePrediction[nftData.card_fullname].prediction}
                            </div>
                        </div>
    }
    else {
        predictionDiv = <div>
                            <div className="nftgallery-label">
                                Prediction
                            </div>
                            <div className="nftgallery-content">
                                The stars are not yet aligned for this prediction to be revealed...
                            </div>
                        </div>
    }

    return (
        <Row>
            {/* <Col md={2} sm={0}>
            </Col> */}
            <Col md={12} sm={24}>
                <p>
                    &nbsp;
                </p>
                <div className="nftgallery-label">
                    Card Title
                </div>
                <div className="nftgallery-content">
                    {nftData.name}
                </div>
                <div className="nftgallery-label">
                    Card Number
                </div>
                <div className="nftgallery-content">
                    {tokenId ? romanize(tokenId) : romanize(nftData.id)}
                </div>
                {attributeDiv}
                {predictionDiv}
                {/* <div className="nftgallery-label">
                    Prediction
                </div>
                <div className="nftgallery-content">
                    {nftData.prediction || dictNatureOraclePrediction[nftData.card_fullname].prediction}
                </div> */}
            </Col>
            <Col md={12} sm={24}>
                <Image className="nftgallery-img-large" src={nftData.image} preview={{ mask: maskShowroom(nftData.name, ''), maskClassName: "img-mask-style"}}/>
            </Col>
        </Row>
    );
  }

export default function NFTGallery({
  customContract,
  account,
  gasPrice,
  signer,
  provider,
  name,
  price,
  blockExplorer,
  isFullyMinted,
}) {
  const contracts = useContractLoader(provider);
  let contract;
  if (!name) {
    name = DEFAULT_CONTRACT_NAME;
  }
  if (!customContract) {
    contract = contracts ? contracts[name] : "";
  } else {
    contract = customContract;
  }

  const address = contract ? contract.address : "";

  const [selectedToken, setSelectedToken] = useState(null);
  const [nftData, setNFTData] = useState(null);
  const [loading, setLoading] = useState(selectedToken && !nftData);
  const [errorMessage, setErrorMessage] = useState(null);
  const [nftBalance, setNFTBalance] = useState(0);

  let tokenView = "";
  if (nftData) {
    tokenView = NFTCard({ tokenId: selectedToken, nftData: nftData });
  }
  else {
    tokenView = <Image className="nftgallery-img" src={"img/card_back_1.jpg"} preview={false} style={{maxHeight: "800px"}}/>
  }

  let errorView = "";
  if (errorMessage) {
    errorView = <div>
      <span style={{color: "#de767b"}}>This card is not available. Only {MAX_MINTABLE_NFT} have been minted.</span>
    </div>;
  }

  // if (contract && signer && typeof signer.getAddress === "function") {
  //   signer.getAddress().then(ownerAddress => {
  //       getBalanceOfNFT({ 
  //         contract, 
  //         ownerAddress, 
  //         provider,           
  //         gasPrice, 
  //       }).then(balance => {
  //           setNFTBalance(balance ? balance.toNumber() : 0);
  //           console.log(nftBalance);
  //       })
  //   });
  // }
  // else {
  //     console.log('here')
  //     console.log(signer)
  // }

  let { cardid } = useParams();

  const tokenIdChanged = newTokenId => {
    if (!newTokenId) {
      return;
    }
    setSelectedToken(newTokenId);
    setLoading(true);
    getNFT({ contract, provider, tokenId: newTokenId }).then(nft => {
      setNFTData(nft);
      setLoading(false);
      setErrorMessage("");
    }).catch(e => {
      console.log('error getting token: ', e);
      setLoading(false);
      setErrorMessage(e.message);
      setNFTData(null);
    })
  }

  useEffect(() => {
    if (cardid && cardid in dictNatureOraclePrediction) {
        //tokenView = NFTCard({ nftData: dictNatureOraclePrediction[cardid] });
        setNFTData(dictNatureOraclePrediction[cardid]);
    }
  }, [setNFTData]);

  return (
    <div id="nft-gallery">
        <div className="container">
            <div id="nft-gallery-body">
                <Image src={title_glimpse} height={"170px"} preview={false} style={{margin: "auto"}}/>
                <Row>
                    <Col md={24} sm={24}>
                        <p>
                        To access your reading, enter your Nature Oracle Card # (1-{MAX_MINTABLE_NFT}):&nbsp;
                        <InputNumber value={selectedToken} onChange={tokenIdChanged}/>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} sm={24}>
                        {loading && <LoadingOutlined/>}
                        {errorView}
                    </Col>
                </Row>
                {loading ? <Image className="nftgallery-img" src={"img/card_back_1.jpg"} preview={false} style={{maxHeight: "800px"}}/> : tokenView}
            </div>
        </div>
    </div>
  );
}
