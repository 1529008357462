import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'img/logo_website.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/nature',
          children: [{ children: 'NATURE ORACLE', name: 'text' }],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '/witches',
          children: [{ children: 'WITCHES ORACLE', name: 'text' }],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '/haunted',
          children: [{ children: 'BITWITCHES: HAUNTED', name: 'text' }],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          //href: '/#about',
          children: [{ children: 'MORE', name: 'text' }],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              href: '/',
              className: 'item-sub-item',
              children: [{ children: 'HOME', name: 'text' }],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              href: '/#Content3_0',
              className: 'item-sub-item',
              children: [{ children: 'FEATURES', name: 'text' }],
            },
          },
          {
            name: 'sub2',
            className: 'item-sub',
            children: {
              href: '/#team_section',
              className: 'item-sub-item',
              children: [{ children: 'TEAM', name: 'text' }],
            },
          },
          {
            name: 'sub3',
            className: 'item-sub',
            children: {
              href: '/#roadmap',
              className: 'item-sub-item',
              children: [{ children: 'ROADMAP', name: 'text' }],
            },
          },
          {
            name: 'sub4',
            className: 'item-sub',
            children: {
              href: '/lottery',
              className: 'item-sub-item',
              children: [{ children: 'LOTTERY', name: 'text' }],
            },
          },
          {
            name: 'sub5',
            className: 'item-sub',
            children: {
              href: '/#about',
              className: 'item-sub-item',
              children: [{ children: 'ABOUT', name: 'text' }],
            },
          },
          {
            name: 'sub6',
            className: 'item-sub',
            children: {
              href: '/reading',
              className: 'item-sub-item',
              children: [{ children: 'READINGS', name: 'text' }],
            },
          },
          {
            name: 'sub7',
            className: 'item-sub',
            children: {
              href: '/rituals',
              className: 'item-sub-item',
              children: [{ children: 'RITUALS', name: 'text' }],
            },
          },
        ],
      },
      // {
      //   name: 'item1',
      //   className: 'header0-item',
      //   children: {
      //     href: '/#Content3_0',
      //     children: [{ children: 'FEATURES', name: 'text' }],
      //   },
      // },
      // {
      //   name: 'item-reading',
      //   className: 'header0-item',
      //   children: {
      //     href: '#nft-gallery',
      //     children: [{ children: 'READINGS', name: 'text' }],
      //   },
      // },
      // {
      //   name: 'item2',
      //   className: 'header0-item',
      //   children: {
      //     href: '/#team_section',
      //     children: [{ children: 'TEAM', name: 'text' }],
      //   },
      // },
      /*      
      {
        name: 'item4',
        className: 'header0-item',
        children: {
          href: '#faq',
          children: [{ children: 'FAQ', name: 'text' }],
        },
      },
      */
      // {
      //   name: 'button',
      //   className: 'header0-item header0-button',
      //   children: {
      //     name: 'button',
      //     children: [{ children: { children: 'WALLET', name: 'text' }, name: 'button' }],
      //   },
      // },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '蚂蚁金融云提供专业的服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于阿里云强大的基础资源',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'img/moonfull_blue.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Our Inspirations' },
          content: {
            className: 'content3-content',
            children:
              '"Nature Oracle" is inspired by nature in all of its glory as it nourishes and destroys, and by the timeless tradition of oracle cards. "Witches Oracle" is inspired by Zoey passion for mythology and everything esoteric. This collection celebrates the facets of the Feminine: Goddesses, women and witches. Wild, wise and free.',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'img/moonhalf_blue.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'The Art' },
          content: {
            className: 'content3-content',
            children:
              'Zoey + Raphy are behind the art of the "Nature Oracle". Each card is hand-made, integrating drawing and digital art. For the Art of the "Witches Oracle" Zoey used a unique technique combining layer by layer painting for her witches and digitally reworked background. Each card is unique and individually hand-made, offering the highest quality of art.',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'img/moonhalf_red.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Interactive Reading' },
          content: {
            className: 'content3-content',
            children:
              'Each "Nature Oracle" card has its own hand-written prediction visible in the reading gallery. Owners of the "Nature Oracle" cards can also draw free personalized card readings on our website. "Witches Oracle" cards come with their lore and stories, visible on the "Witches Oracle" page.',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'img/moonfull_red.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Raffle & Utility' },
          content: {
            className: 'content3-content',
            children:
              'Both "Nature Oracle" and "Witches Oracle" owners are enrolled in bi-monthly raffles to win USDC and NFT prizes from our treasury wallet. We are the first art based collection on Fantom to offer revenue distribution, combining Art + Utility: allowing holders to own the art as it gains in value and, to receive revenues at the same time.',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'img/moonfull_white.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Ownership of the Art' },
          content: {
            className: 'content3-content',
            children:
              'Each NFT token offers 100% ownership and copyrights to the unique art attached to it. Token holders are free to do whatever they want with their high-resolution picture in the Gallery. We can also send ultra high-resolution images (for larger prints) to NFT owners. Contact us at info@natureoracle.com',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'img/moonhalf_white.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Our Community' },
          content: {
            className: 'content3-content',
            children:
              'We opted for a small release for both our collections, so the first owners will be the founding members of our community. The very low supply, unique nature, mythology and oracle theme, quality hand-made art, passive income, exclusive community and interactive website will keep both collections rare and valuable for the long term.',
          },
        },
      },
      // {
      //   name: 'block6',
      //   className: 'content3-block',
      //   md: 12,
      //   xs: 24,
      //   children: {
      //     icon: {
      //       className: 'content3-icon',
      //       children:
      //         'img/moonhalf_white.png',
      //     },
      //     textWrapper: { className: 'content3-text' },
      //     title: { className: 'content3-title', children: 'Future Developments' },
      //     content: {
      //       className: 'content3-content',
      //       children:
      //         'In addition to the bi-monthly raffles, all Oracle NFT owners for both collections will receive an 1 for 1 airdrop from Zoey next collection. Holders will also be eligible to special giveaways, live events, Discord games and huge discounts for our future projects! More details in the "Roadmap" section below.',
      //     },
      //   },
      // },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'The Team' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'img/team1.jpg',
            },
            { name: 'title', className: 'teams1-title', children: 'Zoey' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Main Illustrator + Creator',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Our artist who has 10+ years of experience in both traditional art/painting and digital art. A lifelong student of everything spiritual and magical, the Oracle cards were born from her deep love for nature, magic and mythology. Zoey wanted to create a feminine, nature and mythology based universe, one that feels magical, authentic and esoteric at the same time. ',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'img/team3.jpg',
            },
            { name: 'title', className: 'teams1-title', children: 'The Scribe' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Experienced Developer',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'The Scribe has worked for start-ups and hedge funds as a Python developer and data scientist. A crypto enthusiast who self-taught himself Solidity, The Scribe sees coding as a hobby, with the other being video gaming. He is responsible for everything related to code and behind the scenes.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'img/team2.jpg',
            },
            { name: 'title', className: 'teams1-title', children: 'Raphy' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'NFT Collector',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'By day, Raphy works for a gaming studio as a creative director; by night, he is an NFT collector and investor. He is really excited to introduce a collection that combines art, nature, and NFT to the Fantom Chain. FTM Maxi (of course!), Raphy\'s main inspirations are fantasy and sci-fi landscapes. Raphy worked with Zoey on the Nature Oracle collection.',
            },
          ],
        },
      },
    ],
  },
};