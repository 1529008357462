// Hardcoded list of the metadataURI on pinata of the Nature Oracle NFTs collection

// export const listNatureOracleURI = [
//     "QmUqjurvpgFV9LZTbeEnA88dQGHHCZDkGwX766ymZiSycm", //A14Protection
//     "Qmdg4oRsbJc7Dxu5QLB2iWVtg4nDCFwkcpam24s55LPS8m", //0Balance
//     "QmTeCxAGpw8rbjz4W5dfDdsf5cWcRhi1aPEozCtXzhYcBj", //0Being_Protected
//     "QmRTZobM7RGvkfyP6eWGgJus81pkuHH6eF8k4AW1Rzjow2", //0Between_Worlds
//     "QmZnzrpGrMfty5rZVu1uzGL441XtNWvmwawKHFvfgjnhaa", //0Completion
//     "QmUJrv1roBdn7NvWK5bEd3c7jTQCGyDPKxZfRgAzXAB7YJ", //0New_Territories
//     "QmT5MpPjYj6aNMjrZVXdfUCwuYv2Gr9q5xMocyqQHgYEh1", //0The_Journey
//     "QmYWoSfLrTujo3Ks4MgDvMFFMyDfEfzwHkvBQg7jCex59Y", //A3Balance
//     "QmbLq2h664RaHoAsG1AUDvQcaDX7hmqvgtYHrw7BUtVS6B", //A4Deep_Instinct
//     "QmaeW8VMheiDfQPxdwqBytqsSyb8Yn57dUEUEgqpNHtqza", //A4Knowing
//     "QmTdMB3anCZWvPhjKhSR9EubdV2D5UYNDTjtnHC9VxEd2s", //A18Solitude
//     "Qmbt7dWYK8AqW8CCTXBW7TvUk4GE9m43nFdj6tDwoj5d3Q", //A23Desert
//     "QmaELsY9zLwD3K2uFi8NMDKE8LNjXES1ArqLgPGYuodGn8", //A28Truly_See
//     "Qmdryra918nvMUxtXTVT44d5XEZJKP99FPAPt6QvsfmMie", //A38Wisdom
//     "QmRx1xHu8L7iBHjFmJSZr7WJ7AD8WgBiNdHhE9op84Fkfp", //A2Tree_Cave
//     "QmfGRthdCExcXuNPf9MnpaAnZeRgYon98VZNGDabiUNRvL", //A11Skylight
//     "QmdKGoq4wH5deB2Pm33mtzTYBrZkv6VLt8QY22Q1uzDYDF", //A17Alignment
//     "QmPpF3iHyqcetoZWrVQgm9iFAe8pDdAMKvHyzJwSDrxQYT", //A19Meditation
//     "QmTMQXaygc8RXa5oGDSoMnHX4qXhDHkvAqowi9rZQ2hDqw", //A2Moon_Cave
//     "QmS494nrYzU3MZBaVNmjCTGm2Jd9Kp7UbvCN3ZpZSgWRsL", //A38Eye_For_Details
//     "QmRuYd2yCWq3uZMw1ACyaaBsLqhFWjVyqUTwkFFSSgma45", //0Understanding
//     "QmW6sXC7h2yoGpmoas18RJx1fQWnJaD6inRRThmkW1t4hV", //A14Treasure_Island
//     "QmcvP26XSY3rXMsHQhoC2bjRAnodzEot36sMmKNNonFLaT", //A2Sky_Cave
//     "QmRT6L8NWSHAU7fi1oghBxSz9cF6vgJYYoFJfBwG9x2yhw", //A4Looking_Ahead
//     "QmUMmygbG8v4CsVgHHribZq64eS53LGzuHchFsn25MmQKT", //A1Guidance
//     "QmV6tZEN686u335UfWVSCnzRkaNuY36dkxGYrmea6okBHY", //A16The_Garden
//     "QmYfWM7ujGYezYTZ4fc3EDkqpbEWMR2jhRHWyxiBP2A6JD", //A18Door_To_The_Unknown
//     "QmQmLbCBXZSFPyPA7QgfMgCag8cP4zvndYGCvAdTEpffNQ", //A10Sunrise
//     "Qmb6aaK3bfPQ7CkoTBqoBhFwAwMc2DrNmabtDiMgGxh5rS", //A33Finding_Truth
//     "QmWQgCZpNwYTnEkHPcTa4KUpmB5nH8C3mdzVvd1CJviiPS", //A28Creative_Power
//     "QmVjf7ohf8ebzrdvSsjYr9dGxJQQB3r3gcwmtD5fNvvt3M", //A4Seeing_Clearly
//     "QmShNakRyKAZUeu82Ws4o5sY7mRNPMYNNPujRLS4rDs2Vt", //A1Follow_Your_Dreams
//     "QmZbSfRFcLw1oUBhrGSUmc1iV8SWfMUu74Z3v4yXxTSNmX", //A33Emotions
//     "QmUmhtonDzdf3go2jZwSN5EQu3NUimmqLNjsCKPLq4tcEX", //A32Hidden_Treasure
//     "QmeRVWWEzjC5NzW3KGvfuVXVZVRHnspPJVue1gyrwEjfZp", //0Be_Flexible
//     "QmQ9MVwEDELjYvZY98ZgztvweDyMs8bAaEu4rq5ufVK82W", //A10True_Listening
//     "Qma3oPm2Fv5diZd3JmAkvgHrbaSP1rgJjaAxTZNsTyCv12", //A6Coming_Home
//     "QmcRM69RPMJTb8n2jKyBjHKMpFBdLj96EbyPsJs9nz2Gz4", //A4Water_Of_Life
//     "QmaBvJXPjpRPqJquuQoSVSuioqNMCSDfcMwjCaAUgo72DN", //A23Inner_Desert
//     "QmNYQTjtouptTp5EgSS54Lh6VxxM3ohdKPaVY85u6LVuh2", //A10Voices_Of_Nature
//     "QmTzmT71Fx8TPfwfcfbYHA6SUYaR1RdMrFgfx5j5ehkr6m", //A24Earth
//     "QmbJ7uy4WeTEDHXaeconh3medriJq1fU8yiXgncUapSnxm", //A13Coming_To_Life
//     "QmNrJW2mB9SVcqKzgiNabTV3M9ad3DHUSMhn8MypAJT5jE", //A16Wind_Of_Change
//     "QmaD49BaQsfLaDdMmwq1jqLAwonQsKjgsRvFEqNqzfKdcb", //A38New_Opportunity
//     "QmNsGoQCMEWX1kosG2v2WP54paKfdFHrkzxxtdff5A2csH", //A17Fate
//     "QmbooJWxzyKjoTYP4V5w2VHuebu9iNPrhoWKQTx5XHKxFK", //A4True_Knowing
//     "QmUHohXiXqfHNY4W82VVGqa4qu29XrergLysPChfD5qo88", //A36Time_And_Space
//     "QmPy9gdVma6oLwUWm6KEs2tVMdwHs8rPSPcTUasV4ZY8Ef", //A34Regeneration
//     "QmTm9TRJiLf8GTh8QNx453Yopyo2rVG1pStpRTraRmj8Y6", //A24From_Above
//     "QmbFAx26T2nzznehQVPV2X97hELcHCXKV8brtP1EXF6UMp", //A31Wishes
//     "Qmbb6CxbUqCqKvJbcwG4sYid4mBfqzJysSAUTu7dfYFFcy", //0Strong_Foundations
//     "QmUYs1Esn6NpxtqtYTNtFWSrdHtu35FfKr5CegFG4i8L3Z", //A7Questioning
//     "QmV8ggCB2wrDQZzePs3MmwzFYgs4D1Z4sKswub9owjgyqP", //A16Imagination
//     "QmSUzeoMYpNZv67XSREYUoyrfphZnK1iBRfUTbRqcM8NVG", //A28Treasure
//     "QmUkcWiFph7HFU7fLZVBcsCBHP7AsHDHn365d9Xf4gAvAL", //A14Dark_Goddess
//     "QmRF7RRLVoES8qh2FaGWMtXC99zTBUNTATzWewATLT2tJY", //A3The_Leader
//     "QmYYVHP2NPAtLjUkGP5bf935mVjztPiJpYwCcikeqngsBZ", //A1Choices
//     "QmSzPgcKTvgXaN7e29sPRah8ewo4JqtnprVX1ZNQSsNx6N", //A3Forest_Spirit
//     "QmUo9Pu1wZBQne2wpTdKu69J4egbD2kMMSKFAqPAGWAe1d", //0Slow_Moving
//     "QmUAXZdVwmjyB1i2EWYdxRr9F38VTb9dqGhxRmUV3csSLp", //A8Deer_s_Lair
//     "QmP1A4gXtECTRhU24fqhZfzta9YzgqWnbghb1nNGEaR78J", //A17The_Fates
//     "QmVKpqm4fLZGUDkSstMapgbnEhtNrs3VUuLSBRzHyGRCW6", //A14Moon_Goddess
//     "QmVm6JBfLdzm4ESEbANuTuKptekoQVvM3NSxGa1kc4i5Yw", //A6Asking_For_Help
//     "QmQgWrb9NcBpacqGfW7uggTC4QKLhToijcMZZw3uo8mg9c", //A10Encouragement
//     "QmesdMnYL56XTE4tQ3z72fYvPwKKz9XQx9BE1DFKhEfjGe", //A28Discover_New
//     "QmVu6SbTd1yjsUNtRDW4SpcjSH1LwZZe3jqqtucLDcL1wS", //0Treasure_Awaits
//     "QmTNHbZFPWDhU16vHuX1fePm82TRR4qSQFzie42zBCxbqX", //A24Heal_The_Earth
//     "QmdFCJC8yDab8RMeb13qNwqbShWfRcb2SojHXLKhZkdJ7i", //A27Moving_Waters
//     "QmW7sD35E9gpjpcmn71wNdB5UcfLVh4t7Fhkr5TdBzr8gP", //A5Stronghold
//     "QmW4CFBGruoB8Gx2Jggvfj4ZnbMiCKjkLNbRo2pEtLbHXY", //A26Spark
//     "QmcqLGoUqsWkPQ9KPcxb32GX7FL6k5tUMPECiAfGJozT6g", //A13Creativity
//     "QmdrpixtJ4g9LqccgvatyPewBVE2r3mgYYQKfAvscApy1n", //A33Connection
//     "QmemYDfbk2sHBunVi87Pfg55hmXN25PTmR9Rj9n7E2nNyo", //A25Live_In_The_Moment
//     "QmdFHV6dkj3swJXzZSgyTB718NzCwSxN1WAWjEyVuYi37r", //A4Clarity
//     "QmWGPV5aXzWMTLRWUDhvWe3qrtH1fMkNK7GuHD76iSe99b", //0Moon_Canyon
//     "QmYbhTZ7b1GNrWPSGGUZ6BvkWa7UXqNpUZTXFjLLLd819S", //A38Oasis
//     "QmYaSrZvx2tR9ncJnW9xg4G1tzMKMLE1FLLwnin6jCwm3Y", //A20Purpose
//     "QmNPvJHaaZMtYinP9WAK5tW93g5PdYh4zSBhVFVk5YaDBP", //A30Breaking_Free
//     "QmYre7WicZNjMBzMUbx7VApwUkHaL8u83CaUDWoayiEbHW", //A5Protecting_Treasure
//     "QmSpNJ9c4A4NxRW41zkMKH2JyNvhpBP2TmhifgrDMw1m9D", //A26The_Weave
//     "Qma7vZvmDs6vXD2YBXasSkBNL3wfWxjfnvFnzo2SuEFzn8", //A12Wisdom
//     "QmWRp1pPRMjLXbFM4tgPEpk9ki4KrotRY97EyCjVQzL1sT", //A15New_Discovery
//     "Qmdb3end4cMZ2s1a5HDhhbGNRoiedA7eJCnx2QwUM1L1cR", //A8Field_Of_Dreams
//     "QmQ4hGGnSY8Ldtnts8MrZecvYCHECF1MC23qQ4T8vdGHj3", //A4Destiny
//     "QmbWhnXjj9gALvjw3cLgbJmabHEUqtXn8Y7H5K7jmb1hsH", //A30Small_Progress
//     "QmUbYe2RDsCbtqNogMAcY4dPSTWZryYmHkEa5ZEEazoayq", //A14Yin_Energy
//     "QmcYafLPDuqSAQ5qfgstwNJb33a6jJ1wKrvmEqQV4ymhZH", //A3The_Father
//     "QmU3dMXyQHC3hNFgQxzWEVHYKJEW1bTHuhh73XnREFbeDW", //A4The_Tree_Of_Life
//     "QmT1u1PmdKwKDJZiGkMW27synopST7H2GPGbRziF7JzZFo", //A36Time
//     "QmQz5dfgY7hEkNHhBUmcAWeEaNtRUwZ5iN54rFF9QZweWq", //A18Unfinished_Event
//     "QmbDuQEPxRHevtbL6QCXBXrt6AotatrVVw3PssXnbgRCiT", //A19In_Between
//     "QmegVKxv7WYv7LHcneZUiQRx2it97yZj5msJ871bFRYkPk", //A35Being_Immobile
//     "QmPFq7rz7N1N4PsFNyjBqpwRwvvq7fYj6x42oBy6UPyeLk", //A13Joy
//     "QmVA6gExuJCLrtacJE4eczejNeQBUxUB8RoomJxUVYT44q", //0Dry_Desert
//     "QmYdMzzpbiSteU5t3KBvTMnagYt8Bb3viudna6PB7jjSoa", //A4Solitude
//     "QmX1Xm7JthQx7hfTF7rbK68XCxAHjbsYJ3v8uKhHL5W92U", //A34Sacred_Waters
//     "QmUJMnn6mPRLgEAaxdZjexWUng6bf8FaGpjYPq8SVhYFdK", //A5Storms
//     "QmUqkdzXjhrsNeAzWpZyEmtHou7heNLY2UgCDHyamS1XQq", //A16Finding_Direction
//     "QmcNd71U9JEJn5Sv3Bddg5VGGD9HPoCeFgA9fr9SpAY5rZ", //A27Mending
//     "QmdUnXXJsuTBysSNovU9HGVrqJbyCzSzdmSgiNcnHy6uep", //A35Deep_Rest
//     "QmW18Lnr242W9YiB8q9ZLeuM9r4ktTqSEgBmMC6kTwJHnC", //0Rest
//     "QmbAU4uhiuLhKuf9XTb73D97kSfs4HHdYkSxJ5q3q64xK6", //A20Deep_Knowing
//     "QmeYgMvJwEQbrcpy6cS28F38dohrEDcyNRmqFDKexiFyaj", //A14Hidden_Treasure
//     "QmTpCse6bC1BGrTp1N8fPYQADBwCQWZiS5TYv1MKKfRboq", //A4Surrender
//     "QmX9rtosxneR5KvrrVrYTSQjQzSQWDSkjhkhp92jF2Fqq5", //A29Let_Go
//     "QmX3NWLzCHb6Xwa366ibHdhbLEZmFDUEFphMjXhPg4Rc9s", //A31Leading_The_Way
//     "QmSSMjhMWgZGYCdCB7iezXchpG5q8m4RU2ZpkdccVAVNN6", //0Island_Of_Treasure
//     "QmcyP322TE9Z8k1Eedecz86zhC8ozq4tE4rTKeSuZpaLVi", //A28Small_Pieces
//     "QmbCQxUE5W668N3BGEM3DF2rp42fYzq37w1eAikqUxStQh", //A21Rock_And_Mountain
//     "QmbyMzKW4EWSNZ8uLVeNnudu1e9ebKcDVS5CdEUYjvhzXe", //A9Following_The_Leader
//     "QmbbBq6HLJfcVeW28zD32aHf2bXiFnxUfzXvB2VE9T66Gx", //A25Letting_Go
//     "QmSSpjfTnFfyVanb3oP2TS4WhLYdJu8wzHDNHUkDB3rMZm", //A21In_The_Middle
//     "QmXxfg7e3xGXgChj978mxEMa4UFHAFsbNjsKU5Ym1DKSds", //0Have_Faith
//     "QmQmD67ZHa7B5fN1YUhgxMfSVRZqcWUxDPDyh3DMySZ3rc", //A4Slow_And_Steady
//     "QmQPvJyBecZ3GG83ptKu5tDoom9yHJp2CSDRifiMD38PAg", //A13Magical_Stream
//     "QmU6a3v4SmLRDPWuWUd785izZVUj9GkfSGYL4wbUJ1hTbt", //A22Courage
//     "Qmf6hwz3YoMSoeZ7dSSY74tWJu3tZpZXQEabTfedaf2zJH", //0Ancient_Tree
//     "QmWWTwDtKzbnU2G6mgoCB5mnddSxpHmLNpEpzD3vEpJU5p", //A4True_Magic
//     "QmRtBZPvvTZxsX7jub3ih2Sobn8AMMBrzX9swpVazJQbCQ", //A37Heart_Of_The_Jungle
//     "QmQvKWN1EsoeapHUwq5vDV8r38SpuVYngda8HXYtnXpi44", //A4Prayer
//     "QmPKJVEC4eyUgn7EnnLg1j1GnEnkYri1pMPyCLRPeogg5J", //A23Find_The_Way
//     "QmRpGLEWxeM2fhQVT9CVBRrAFEMYRvv74oJwpH5LSmVqWT", //0Manifestation
//     "QmWunnwSUa1mr4GmTJ9hu93R2ufdhexGLT1kiuaw2YqwC3", //A28Wide_Open
//     "Qmbgfaa2BigRoxLawq4CLfFtckQXC69KCJXmJ5XcYczpnk", //A4Magic_Stream
//     "QmYNZUkQ34YvBbzvSQu8SWHnZ18kNMcegw7oA5ywppKSHC", //A32Hidden_Secret
//     "Qmc9K8xnBNsWRvvEV9kg7AyrPbseLP5A334xeVSBoQZnwZ", //A9Strength
//     "QmYJcFhv5UsiwybosNV61eZY9qrpgU4NpHyy8iQJDsiDTF", //0Stormy_Night
//     "Qmd2FdJBkpKj9o6XsnjAzy3iLZaFPFHciK3u168kHNSQa9", //A33Commitment
//     "QmSftRViRmbzP2Li1nY62WX8cgx1R84S2jcKGzRhu6nxm5", //0All_Together
//     "QmW2EFfCgMGbSJT65Vh2Xu3isQYUXd5uf29S4mB212hMvN", //A22Clarity
//     "QmPiXTaXWEjwMv5YexWGhTktmcemRo92PGAGJTwY57QS9B", //A7Mystery
//     "QmRHNy8dYkJ6jf2fwpkT4FtrXmPzQ3hLJkc28AvoivtXy2", //A20Protector
//     "Qmcbcvq3JCiy3NRWJ8KibJYqZFVhnenbzWZvStEU578eNH", //A34Sea
//     "QmfU2CTB3TgpLvNyYZ6zJCNGcwzTRbPx5YikNFKgezbtRr", //A33Balanced
//     "QmcMaeSJs27VdoKGo2Scg5Pit5k7qGid1m3aVmGtnm1mVb", //A29Road_Fork
//     "QmPo4bS4Js3JMzetar2D5zYWqQwA2AV3U9Q9LbSPR8gezE", //A31Enlightening_The_Way
//     "QmSSEMB91UYQasenYo2bJZqNkZ1vdbW9d35X2P9Q97g5hM", //A2Water_Cave
//     "QmbznukZEJ7opeRssDqRtct9omEted7ci3438ED52HUZHC", //A34Deep_Unconsciousness
//     "QmacfP9MXwdECJKeoS37DtgcL1kvhNKFsvcufTxnDyB6hp", //0Metamorphosis
//     "QmSGQDfLuvxUmXvboWNmxrGzrecuHLbX62efo2qp1bRAmp", //A4Wisdom
//     "QmTmArGJXWE4EFPfLx8QFDu5BpdNahvEGCkhoxPtDsnTCG", //A1Light
//     "QmSyFF2RgLFbHvkaBUZ9bfGHYD6HYxiyALmb3fNfa4xypa", //A22Kind_Presence
//     "Qmdu2gsJCpGBvXVKq5U9quDBb1nAeKYsAbrxSNZBqAnT8e", //A35Slow_Moving
// ];

export const listNatureOracleURI = [
    //"QmRD399PvMLmunMT2duDuDjgbrTGsq8Z9zzfUa1YNfKDNY", //0Ancient_Tree
    //"Qme5vZ9hajPCzirR8LyJUakjcZvND6hbA3mmtbdj79Qfy2", //0Have_Faith
    //"QmVRE7WuQFwKvm3sYEtfER6n6xJbVZHzYV66iYwUMJHmCb", //0Metamorphosis
    //"QmUJ6U2ETyAKVSQCyegrZ362X3iXAMTizYEToHKVmrTHYY", //A16Wind_Of_Change
    //"QmeYYuFhU4vM2HXwj8PZZ9Gv7nJz6Gza9Y7t9s5C7RqMxo", //A17Fate
    "QmbjJmbQfuAvYPktpu5Vc7y9RVWV2yEKrSgstrj9AtAP3x", //A1Light
    //"QmeKQbTsVWysvm4mbE5217nB8bEp1GehPitbdidEwqkpi6", //A22Clarity
    "QmWcJHyVYwSr1oHrbTyKEgeHo7iZNGEWgoCBjYSXnxg7SR", //A22Courage
    //"QmXurkJ26mSNHABNiu2D1cqt8q34AC4tAa7QKc99YswSBC", //A24Heal_The_Earth
    //"QmSP3tBNDpPxJxAGsHQSY9vJN5iZn1AHzPw2c6Bg3hkga6", //A28Discover_New
    "QmeSzzyedAgk3fUoDbGTn5CgtRXxU8x2vQFj1kGDb97mqq", //A29Road_Fork
    "QmcSoy1pPUSSyAdGwF6YYJxhhrtLiuzQG5rd55NE6ogMyP", //A34Deep_Unconsciousness
    //"QmSkeEiwew4q4YhHQUpjgZ7zJcTWA8fs2cUViexmcLWynr", //A34Sea
    //"QmUAXK3mEMoagbPNzAENjAbmnvBDDddv3EScyuK7zpkTYv", //A35Slow_Moving
    //"Qmce22vvhU4W3PhFfgmLxiYyxk98HoR7C1GLke19HgotVJ", //A38Oasis
    //"QmWRgVDQEn2TfazNJmAVWNwQdvMesDG9SvZLA3VgMoKpSd", //A3Forest_Spirit
    "QmSQn9xAmHpsajRNUZ33pZ8ve1KZaqw8cMrRKXsFVro3Uw", //A4Magic_Stream
    "QmUxYhBZ5UGYJji9KcVsVyPpecxXfCo1xw84UkMib1e5u5", //A4Prayer
    //"QmexTiZsnhPEVTMctqnps2S8yjgLVJrr7WAHrf4mmLAc3L", //A4Surrender
    //"QmXt54LrSuRLcpp1RG6x6X6DEjQXY4hPcrdGEZWgN3Vj1i", //A4True_Magic
    "QmPAER166kZq6sMmQJGAck6FVgfLZRMgaqr7iD43xe6TcB", //A7Mystery
    //"QmdsoHgv68avBxZ8A7QDgazDm1X4d26Rm6CEjZpwSpS6vV", //A9Following_The_Leader
    //"QmbsSbqrM7fMCHR1raqXzpCKuRx7wd2tmTLF5RVeRoW5ap", //A9Strength
    //"QmSpgY6htdkj6kcttDa8KR2D4moRC9Bn8zpDMW3DeL5PDe", //0The_Journey Signed
];
    

export const dictNatureOraclePrediction = 
{
    "A14Protection": {
        "name": "Protection",
        "id": 1,
        "image": "https://gateway.pinata.cloud/ipfs/QmdhSrLEf6Zm1vYQ9kkPVUccUXXGytgRZnEkv9uKq8hiPJ",
        "rarity": "Uncommon",
        "prediction": "A woman holding the light stands in the midst of the lake. The moon shines on her. This card is about being protected and hold in security. The feminine energy is the one of the land - of everything that surrounds us and uphold us, of our body and every cell of our being. This energy is linked to the cycle of the seasons and to the Earth herself. This card is about this protective and enveloping energy being present in your life. Whatever you are experiencing, she will work in the background to help keeping you safe on the physical and material plane, and she literally uphold you."
    },
    "0Balance": {
        "name": "Balance",
        "id": 2,
        "image": "https://gateway.pinata.cloud/ipfs/QmbiBSdPvJWNNzNELK9ccs7TCAZsJdWssbBA6LegwDx2gG",
        "rarity": "Uncommon",
        "prediction": "Life offers experiences that are challenging and others that are nourishing. Sometimes our life feels like it's flowing in a direction we like, other times we feel like we are pushing a mountain. But this card tells you that over time, both sides strike a balance. Life is about learning, and about moving from action, challenge and hard work, to being in the flow and then from resting to hard work again. It's the case for everything. Without change, we will not learn something new nor challenged enough to develop new skills. You will always oscillate between different states of feeling and being, between difficulties and flow, remember all are part of your human experience."
    },
    "0Being_Protected": {
        "name": "Being Protected",
        "id": 3,
        "image": "https://gateway.pinata.cloud/ipfs/QmXijKtXnMTFewB65gWENVzf58Q2uXFnpahMtvf24TGf2Z",
        "rarity": "Uncommon",
        "prediction": "This card tells us that loyalty is an important theme right now. We should be focused and nourish the relationships that are important to us. Strengthen friendships and work relationships to ensure their integrity and trustworthiness. This card tells you that now is a time to reevaluate the people in your life, and also how you are acting with them. Who is really important and loyal, and how loyal are you with them? How much do you show them that they are important to you? Other people may tell you something without thinking it - is hearing compliments more meaningful than action? This card tells you to look at all the angles of your relationships and reevaluate your priorities with different people. As humans we strive to connect, but is our connection truly of value to us and to the other person? Are those connections worthy of our time, energy and commitment? "
    },
    "0Between_Worlds": {
        "name": "Between Worlds",
        "id": 4,
        "image": "https://gateway.pinata.cloud/ipfs/QmczevTgt2nBseYrnkYEoCznP6x7qdAqHLUmZMTBN9APwm",
        "rarity": "Common",
        "prediction": "This is a place between what was and what is to be, a place of ending and new beginning, a place of mystery. The seeds that were planted in the past begin to grow and to take root, but there is nothing to see on the surface yet. The future growth is still to come. This card tells you that the past is already decided and the future is still weaving, it's a time of transition. It's a time where neither the past nor the future are fully engaged. This card tells you to be patient, to stand strong in your roots and to wait. Wait for the transition to happen and for the future to become clearer. There is nothing to do for now. This card tells you to trust the process, and also not to overthink: whatever we think or do, we don't have influence into what's weaving and becoming. We are the patient and quiet observers."
    },
    "0Completion": {
        "name": "Completion",
        "id": 5,
        "image": "https://gateway.pinata.cloud/ipfs/QmYjEfAnw5Q5bPBdsuzKJT6iTS2R1QHDWY44iSEJoZ3EgP",
        "rarity": "Uncommon",
        "prediction": "This card representing a perfect circle is about completion. This card tells you to make sure to complete your project now, before starting new ones. Everything in nature has a beginning, a middle and an ending - the same should be of our creations. A balanced creation or project should have an ending before being sent out  into the world. This card tells you that now it's the time to put your nose to the grindstone and complete your project. You may not feel the excitement of the beginning, but this card tells you to keep going. This card is also about taking care of our belongings, and not letting them accumulate, creating clutter. You may want to pay your bills, complete your assignments, call the friends you planned to call. Tying up loose ends allow us to move forward fully without having part of our mind and energy staying in the past. It also allows our project to be complete with a beginning and an ending, and thus more balanced and likely to succeed."
    },
    "0New_Territories": {
        "name": "New Territories",
        "id": 6,
        "image": "https://gateway.pinata.cloud/ipfs/QmeaKgH2pYGZAR5tyKRS6M296GzTzyuAXSQZzzMuVpiZSP",
        "rarity": "Rare",
        "prediction": "You're entering uncharted and new territories in your life, and you can expect new opportunities and also new challenges. This card tells you about being open to new experience whatever form it may take, accept and see where it goes. Something surprising may present to you, something unexpected, or something that brings you joy. Anyway, even if the experience is uncomfortable - or enjoyable - you will learn from it. If it's not in your habit, this card tells you not to reject nor avoid it. "
    },
    "0The_Journey": {
        "name": "The Journey",
        "id": 7,
        "image": "https://gateway.pinata.cloud/ipfs/QmTpA7zA9iEwVnxWkmxVJu4Vgw85Y513vPr5Ny1NnHy4JH",
        "rarity": "Common",
        "prediction": "This card shows a time for a journey, a move, or a travel. This card could also be about a new relationship that leads to learning and new experience, or about an important new project. The key words are: learning and discovering. Keep moving forward. However, this journey occurs, it will take you to new places and to meet new people. You may have to leave your safety and what you know behind, but this card tells you that there is no point to be scared, the timing is perfect to pack your bags literally or figuratively and explore new horizons."
    },
    "A3Balance": {
        "name": "Balance",
        "id": 8,
        "image": "https://gateway.pinata.cloud/ipfs/QmQLgXfUUePbYZgzmmhzm55sjuLqmpWidU7cpG6eCvb9iA",
        "rarity": "Uncommon",
        "prediction": "This card is about finding balance in our lives. The world in which we live can be overwhelming, information is everywhere, the fomo is real, and we are often scared  to \"miss out\" if we don't act right now, in the minute. This card is about the balance between ourselves, our own silence, our recharge time and the effervescent world in which we live in. This card tells us that if we feel our life is \"too much\" then it probably is. We should always allow ourselves to slow down, rest, doing nothing when we feel the need to. "
    },
    "A4Deep_Instinct": {
        "name": "Deep Instinct",
        "id": 9,
        "image": "https://gateway.pinata.cloud/ipfs/QmPcDWx6wAk3ycUAohSE3gVjca4niMwrdQkYqTAVu2pP9v",
        "rarity": "Common",
        "prediction": "Beyond our thoughts, emotions and especially the noise in our head, what does our gut tells us? The \"gut feeling\" usually express as a deep and body based knowing - similar to fear or anxiety, it could be an impulse toward something or a warning to avoid something. This card is about listening to our body-based instinct and intuition instead of overthinking. Your body and instincts have the answers in this case."
    },
    "A4Knowing": {
        "name": "Knowing",
        "id": 10,
        "image": "https://gateway.pinata.cloud/ipfs/QmbnMpz9Xda1q1rZbWe77VLFdSZJ8mnKv5CgGHvY5PKK5c",
        "rarity": "Common",
        "prediction": "A bright ray of light shines on a large tree. This card is about deep inner knowing as Tree has been associated to wisdom by our ancestors. Whatever decision you need to make, you have the answer. It may not appear logical or even making sense to you right now, but your deep inner instinct just knows. This card tells you to follow your inner knowing and to trust it Do not focus on how the situation presents itself. Sometimes when we are stuck, there is no logical or rational solution, overthinking won’t solve our issues but our deeper instinct maybe know what's better  for us in the longer term[GP1]."
    },
    "A18Solitude": {
        "name": "Solitude",
        "id": 11,
        "image": "https://gateway.pinata.cloud/ipfs/QmZXTcZ27Srs8GPWCV74DwULDwMmTKJ9rP7hZq5G6geDUi",
        "rarity": "Uncommon",
        "prediction": "This card is about taking time alone, and emptying our mind of superfluous thoughts. So, we could allow our body to relax, and stillness to flow through us. This card tells you that it's not the right time to begin something new, to push forward or to work more. It's rather a period of retreat. Sometimes even though we don't feel it, our body is worn and tired and needs rest, so does our spirit. The noise of daily life, of social medias, and of our work can wear us down. And when we get used to this state, we won't even be able to feel it and acknowledge it, it becomes our “normal” state. This card tells us that our body and spirit need rest, and if we slow down, we will be able to replenish our energy and be more efficient when we get back to work."
    },
    "A23Desert": {
        "name": "Desert",
        "id": 12,
        "image": "https://gateway.pinata.cloud/ipfs/QmaHaLgU2SrncyssRqoV78s254NXXR85JKWRkiKkvqb1Bs",
        "rarity": "Common",
        "prediction": "Do you feel a lack of inspiration lately? Or if you still have inspirations your ideas may look a bit scattered? Do you feel like sometimes it's difficult to see clearly? This card tells you that this is fine right now: sometimes in life we are supposed to lay low, the same than during a desert storm. We lay low, we save our energy and resource and we wait for a better timing. The storm will eventually pass, and if we’ve saved enough energy and resources, we will be able to reach the Oasis. This card tells you that it is fine to wait, and there is no point to act right now. Sometimes we just need to let things pass."
    },
    "A28Truly_See": {
        "name": "Truly See",
        "id": 13,
        "image": "https://gateway.pinata.cloud/ipfs/QmZzWaawBW19TGRpFmrYgDxZgveVEdSg8oAFKBwuoiUKkS",
        "rarity": "Uncommon",
        "prediction": "Most people see the world through their own personal lens. They closely identify with their feelings, thought patterns and past experiences. They come to think this is the only reality - but it's their only reality. This card tells you that you should look at your circumstances from a more neutral point of view. And now is the right time to do that. Instead of using your own lenses, you can put yourself in other people's shoes: what would've you done if you were from a different country, with a different culture, or from a different family? This exercise will open your eyes on how our daily experience and the way  we see the world depends on our t conditioning and past experience. We are part of our society, culture and family, we have our own past, and all those elements woven together form our \"Lens\" and our way of seeing the world. This card asks you to be conscious of it, so you will be able to keep your eyes open and see beyond your personal lens. "
    },
    "A38Wisdom": {
        "name": "Wisdom",
        "id": 14,
        "image": "https://gateway.pinata.cloud/ipfs/Qmcti3BuBocVBd38LkArAr2U8GCWKTKHxRg9YUvWBugWzX",
        "rarity": "Rare",
        "prediction": "This card shows a time of inner preparation before taking action. Before you can reach outside yourself, you should reach within to find purpose and focus. This period is a time of focused waiting and stand still. It’s a time to define your goals, find directions and prepare yourself before stepping into a big project or an important relationship. Once you are in the midst of the action it will be much more difficult to gain detached clarity. So now is a good time to take note and focus yourself."
    },
    "A2Tree_Cave": {
        "name": "Tree Cave",
        "id": 15,
        "image": "https://gateway.pinata.cloud/ipfs/QmSASgY14PokacyAYikUswbA3m5qgunVLgHV9HaKG15hEq",
        "rarity": "Common",
        "prediction": "A giant tree looms above the deep cave. This card is about new birth and the strength of life in every situation. Sometimes we don't know how to deal with endings: be it the ending of a project, a partnership, or something else. Our societies tend to praise what's new, colorful and exciting, but endings are often seen as sad. This card tells us about the power life that is at the heart of everything: in every ending there is the seed of a new beginning and a place for a new sprout to become a giant tree. Something may be ending in your life, but you are making the space for a new beginning."
    },
    "A11Skylight": {
        "name": "Skylight",
        "id": 16,
        "image": "https://gateway.pinata.cloud/ipfs/QmdqnGTwvqrmKEF4PXBpkdwvQZ34F7RTQBBGB4zuiwpcsW",
        "rarity": "Rare",
        "prediction": "Skylight beckons. Come to yourself and trust your intuition to lead you into places that may not seem logical at first. Your hunches will be right on target, this card tells you that you should trust your intuition which is sharp right now. If you have a question you can't answer rationally, your intuition will help you. You can see beyond the surface of things and read between the lines. This is a good time to give a try at listening to your inner voice and hunches."
    },
    "A17Alignment": {
        "name": "Alignment",
        "id": 17,
        "image": "https://gateway.pinata.cloud/ipfs/QmQPfPeCiEDkfRWbDuUfJtvDq4vYiVNhN1bjgLKuwrFRFe",
        "rarity": "Common",
        "prediction": "We are all part of the weave of life, the outcomes and events in our lives are entwined with the ones of the country where we live, of our family, our neighborhood, and everything around us, seen or unseen. And this card tells us that we are in alignment with those weaves. It's like a moment where everything just clicks together. This is this moment. You've stepped into alignment with everything around you, your own plans, energy and weaves resonates in harmony with the ones around you. It's a time of luck and opportunity, you may want to remain open and aware, so you'll be more able to spot and act on the opportunities coming into your life. "
    },
    "A19Meditation": {
        "name": "Meditation",
        "id": 18,
        "image": "https://gateway.pinata.cloud/ipfs/QmQxUC3Yjd6kfwcvfoZuH9gTa7SjNGz2swaBd2GGHsgnQq",
        "rarity": "Common",
        "prediction": "This card tells you to slow down and to focus on your inner self. Your inner child especially needs you attention. This card tells you to look closely at your way of being: are you fully present when you are taking a decision or are you acting out of past conditioning and expectations? When we are wounded in our younger years, we adapt and build a protective layer: it could be anger, fear, or detachment for example. We learn to instinctively react in a way that would keep us safe. Now it's time to claim this part of yourself back, you're not longer in danger, and you have lots of potential. Those past pattern of reaction are limiting and it's time to make peace with them."
    },
    "A2Moon_Cave": {
        "name": "Moon Cave",
        "id": 19,
        "image": "https://gateway.pinata.cloud/ipfs/QmUeDAoKL8GMt9P6fiQRmdVJGPzg7fxsNaLZrqMHGaauCW",
        "rarity": "Common",
        "prediction": "The rushing waterfall falls into a deep cave. . This card is about our imagination and creativity flowing in a cave that represents our own inner world, picture your imagination and creative power as a stream of moon colored, silver light and bright water - bringing rejuvenation into your inner world. Welcome this stream of creativity, play with it and let it flow and bring clarity and new ideas. This card is about the stream of our inner creativity and how we should stay open so it could flow in and rejuvenate our inner creativity."
    },
    "A38Eye_For_Details": {
        "name": "Eye For Details",
        "id": 20,
        "image": "https://gateway.pinata.cloud/ipfs/QmV6cPb3AePn4gZYjxsfRVjxnqk312PUFUjioygd4vL4bM",
        "rarity": "Rare",
        "prediction": "There are times when the big plan is meant to lie dormant in your mind for now, so that you can pay attention to the simple daily chores in your life. By lightening parts of your life and look at the details you will be able to clean house and strengthen the foundations of what you have already accomplished. The act of pulling off dead leaves, watering the dry soil and prune the branches of a tree is an important step. By taking your attention away from the future and your goals, you step into a receptive mindset which allows you to bring together everything you’ve done so far. So, your future steps will be built on solid ground."
    },
    "0Understanding": {
        "name": "Understanding",
        "id": 21,
        "image": "https://gateway.pinata.cloud/ipfs/Qmba1K7Y6VByv75e3oiBLoa2AMtxMgUvMD7Lvim4Y29ZaC",
        "rarity": "Uncommon",
        "prediction": "This card represents the tree of knowledge and is about the \"why\" in your life.  Understanding your own motives will help you see more clearly. If you are asking a question, this card tells you to ask yourself why does this question even bother you? Why do you need an answer? Sometimes we spend lots of time asking the wrong questions. It could be linked to the fear of choosing: we prefer to stay in a status quo instead of making choices and being responsible for their consequences. It could also be linked to our inability to let go: we ask a question but we already know the answer, it's still impossible for us to accept it, so we keep asking the same question hoping to get an answer we like better. This card tells you to reevaluate your own motives so you could gain better understanding."
    },
    "A14Treasure_Island": {
        "name": "Treasure Island",
        "id": 22,
        "image": "https://gateway.pinata.cloud/ipfs/QmUVqBXtyfWi2Sfm7UqqLHxGrn7dbMiik3NBj38SJmttsE",
        "rarity": "Common",
        "prediction": "This card is about your inner resources: be it your energy, your creativity or your skills - this card tells you that you have lots of them. You've spent time cultivating your skills, your competence and yourself as a person. This card tells you that it's time to put those treasures into work. You have all the tools you need to be successful in the different domains in your life. One thing you may want to work on is confidence - it could be scary to put ourselves out there in the world, especially in the age of social medias and cancelling culture, but you should be confident because you’ve worked hard and have all the skills. It's your time to build on them and to shine."
    },
    "A2Sky_Cave": {
        "name": "Sky Cave",
        "id": 23,
        "image": "https://gateway.pinata.cloud/ipfs/QmcbqrQAMXjfv6c1B4Q1b1da9i5ipApBYanWH1imjJY32c",
        "rarity": "Common",
        "prediction": "This card full of colors is about our imagination and also about us being open to all the colors and facets that exist in our lives. Life is an adventure, and often new opportunities will arise that we don't know how to deal with, or if we should take it or not. This is one of those times. This card is about discovering new and exciting territories and treasures. Wonder awaits, and if you trust your inner guidance, if you just breath, and are mindful of how to work with those new opportunities, you shall be fine."
    },
    "A4Looking_Ahead": {
        "name": "Looking Ahead",
        "id": 24,
        "image": "https://gateway.pinata.cloud/ipfs/QmXowB2KGYvjX2mMVcDVcPon5W4EHFo7MMJxNoQDH7djPH",
        "rarity": "Common",
        "prediction": "A deer in the depth of the forest looks in front of him, he looks confident, what does future has in stock for him? This card is about the ability to look ahead in the future, and further away, whatever our current situation. If something is happening in your life that you don't immediately accept, enjoy or even understand, try to project yourself in 1 or 2 years. Will you still care about this situation? Will it have a long-term impact in your life or is it more like a ripple in the web of events in your life? This card is about taking a higher and longer-term view in our lives, instead of being stuck. Sometimes we have difficulties that are temporary, and we have all the tools to deal with them right now and then let them decompose and flow into the past."
    },
    "A1Guidance": {
        "name": "Guidance",
        "id": 25,
        "image": "https://gateway.pinata.cloud/ipfs/QmapW5P46AbTBFG1nWEp5dP2JckB3DyWCt7VXMjYzU9Fqn",
        "rarity": "Common",
        "prediction": "A castle flies in the clouds and in the dark sky. This card is about being guided: the durable foundation and the light of the castle guides you from the above. Whatever you are doing, you are building a solid foundation and you are receiving the right guidance for you. This card is about trusting what you are doing right now and trusting that you are on the right path, even though you cannot see the result right away or as quickly as you would like. "
    },
    "A16The_Garden": {
        "name": "The Garden",
        "id": 26,
        "image": "https://gateway.pinata.cloud/ipfs/QmYsGJQxEMJveWLQybropjgcGV221sj5T9pvDmK25rqEhb",
        "rarity": "Common",
        "prediction": "This card tells you that you have seeds of regeneration within you. The garden is a magical garden floating above us, an evergreen place: it's the garden of fertility and new growth. This garden is linked to the energy of new creation in nature, to the wind that blow through the land in Spring, scattering the seeds and nourishing the soil. This card tells you that you are ready to begin a new chapter in your life, be it a new job, a new relationship or a new creative project.  This is the card of the first step and of beginning something totally new. The energy of new creation flows through you, and if you wish, you can tap into it. If you have hesitation, this card tells you to not worry "
    },
    "A18Door_To_The_Unknown": {
        "name": "Door To The Unknown",
        "id": 27,
        "image": "https://gateway.pinata.cloud/ipfs/QmSGSaqPiY46JjK5d9eMHX3CR4qkhG7qxHKTdYW3ekhK6B",
        "rarity": "Rare",
        "prediction": "This card tells you that you near the completion of a cycle of project or learning process. And you are ready to move forward. Sometimes we can have perfectionist tendencies and we want to keep improving  our project, knowledge or work, but it’s not always necessary We can also appreciate the comfort of something we know, and of our acquired knowledge. We feel safe when we understand something. This card tells you that you've learned and done enough, that you’ve learned what you needed to learn, and now it's time to move on. What awaits you if you move on could be a new creative project or another opportunity to acquire new skills and knowledge.  "
    },
    "A10Sunrise": {
        "name": "Sunrise",
        "id": 28,
        "image": "https://gateway.pinata.cloud/ipfs/QmXY9LjmjcSa2pPKsU3FDPTfN4o3tCcxP6TcQJ68p9qxpv",
        "rarity": "Uncommon",
        "prediction": "This card is about the time of the day where we feel new - we just woke up, and for a slip second -before being aware of our surroundings and our everyday schedule -everything seems new and everything seems possible. This card tells you to remember this moment of \"everything is new and possible Your life may seem to be full of repeating tasks and redundant responsibilities, it may take more time to accomplish what you want to accomplish and to realize your dreams. But it is possible, and keeping this positive and open mindset will help you on your path to your dreams."
    },
    "A33Finding_Truth": {
        "name": "Finding Truth",
        "id": 29,
        "image": "https://gateway.pinata.cloud/ipfs/Qmeq6Xt8CVvzDRiG9GGk9z8UR9YW431JdSe4KAcPKF68AG",
        "rarity": "Common",
        "prediction": "Truth could mean different things - there is the Truth, which is the deeper meaning and pattern of something, and there is a truth which is the subjective truth and differs from one person to another. We all have our personal truth, our own way of interpreting the Truth. As human it's very difficult to touch the deeper wisdom and meaning of anything, we are always further than we thought. But this card tells you that the most important is believing in your own truth. This is a time to speak out, be transparent, honest and to be open with what you believe in. It's time to be truly authentic even though it could be a scary experience, especially in our age of social medias, but it will set you free, and will lead you to opportunities in alignment with you own truth."
    },
    "A28Creative_Power": {
        "name": "Creative Power",
        "id": 30,
        "image": "https://gateway.pinata.cloud/ipfs/QmYzvsEyb1hPX86jBWjZnScVME4aeZZxeBChdCgeDC8ivb",
        "rarity": "Uncommon",
        "prediction": "This card is about the creative power of taking action. Red is often seen as linked to the active element, as the blood flowing through our body and maintaining it alive. This card is about the energy of creative impulse that propels the world forward and manifests thought, desire and plans into being. On a personal level, this card is about the energy and impulse that fuels our plans and our actions. This card tells you that now is the perfect time to act, and to begin to build. There is no reason to hesitate, you can use the surplus of energy and motivation to build your desires into concrete form. This card could also tell you that you are full of creative impulse and energy, and you need to find a way to channel it."
    },
    "A4Seeing_Clearly": {
        "name": "Seeing Clearly",
        "id": 31,
        "image": "https://gateway.pinata.cloud/ipfs/Qmd5XQVXANnxZ8Vmk74VPmgfHe5gCeyygru1D9sTsELfQE",
        "rarity": "Common",
        "prediction": "Sometimes we come across what appears to be insurmountable obstacles and fog, we aren't able to see clearly the people, the situation and the future. It's like a wall separating us from where we want to be. We might think that climbing it may be the answer? But rather than using brute force, we may want to ask ourselves if it's necessary to go over the wall at all. This card is about questioning the path we are taking now. If we run into difficulties, we can ask ourselves: Are those difficulties necessary? Do I learn something from it? Does the road beyond lead somewhere? Or we may want to find another path: sometimes difficulties aren't necessary and are avoidable. This card is about evaluating your situation before taking any decision."
    },
    "A1Follow_Your_Dreams": {
        "name": "Follow Your Dreams",
        "id": 32,
        "image": "https://gateway.pinata.cloud/ipfs/QmTTspBj62L6Ny4vXu6ABzcrFCFYvftwkJtAQA4kdaTTAW",
        "rarity": "Common",
        "prediction": "Rise above the clouds of thoughts, what do you see? What do you hear? Sometimes our mind is everywhere, when we rise above all the thoughts and the background noises, the inner nudge of \"what we should do\" becomes clearer. And we will be able to find the way that leads us to where we are supposed to go, we just need to truly listen. You will receive the answer to your question if you take a pause from the noises in your life. It could be meditation, and being detached from your thoughts. It could be not touching social medias for a day, or trying to stay out of mundane conversation from your colleagues or family members."
    },
    "A33Emotions": {
        "name": "Emotions",
        "id": 33,
        "image": "https://gateway.pinata.cloud/ipfs/QmNUuDQfSgTrFmvmeYNkMm2e1uvGDF9sk2TRCSHF7Bo6sV",
        "rarity": "Common",
        "prediction": "This card is about emotions and how we live with them. Emotions are part of being human and are linked to the biological functioning of our body, but it's also a tool for us to connect with people all around us. Emotions are effective in helping us communicate with other people. This card tells you that emotion is about connection too and sometimes it's important to let go of the need to have things our way and instead, to be conscious of the emotions of other people. Sometimes it's not about being wrong or right, the situation or choice may not even require logic. Sometimes it's about emotions: people being heard or feeling accepted and loved. This card tells you about the importance of emotions in communication, connection, relationship and working with other people."
    },
    "A32Hidden_Treasure": {
        "name": "Hidden Treasure",
        "id": 34,
        "image": "https://gateway.pinata.cloud/ipfs/QmRcjsidZ1tg7PdTUJA9grQRWeF3xAW3tLFejWJc7xSP1R",
        "rarity": "Common",
        "prediction": "A woman swims toward a treasure - this card is about hidden treasures and tells you that you may have a person or something - a skill, a project etc. that's very valuable in your life. And you don't see the full extent of its value yet. This card tells you to look more carefully, and to be more present so you can spot this treasure. It could be a project that's very promising, if you know it, you will be able to put more time and energy developing it. It could be a relationship with someone who has great compatibility with you. This card tells you to look more attentively to your life and to find valuable growth opportunities."
    },
    "0Be_Flexible": {
        "name": "Be Flexible",
        "id": 35,
        "image": "https://gateway.pinata.cloud/ipfs/QmUipsG8bvVSe87q7zagAtTa8UziW3eKUomYfGWMqrrZD3",
        "rarity": "Uncommon",
        "prediction": "This card tells you that now it's a time to be flexible. Like a tree - with its root solidly planted in the ground and its branches bending with the winds. This card tells you that being supple and flexible will allow you to go through life's storms.  By being open to learn new things, to change while being firmly grounded in your roots you will be able to go through the events in your life with more ease and tranquility. Instead of trying to change what's around us, we are adaptive, and as in nature, accepting change instead of trying to change everything to fit ourselves is a more efficient way to go through life."
    },
    "A10True_Listening": {
        "name": "True Listening",
        "id": 36,
        "image": "https://gateway.pinata.cloud/ipfs/QmPC5XM4FyiCxzkXaD1J8F1yXb4n2J2yVEuXtSCy1KbgBQ",
        "rarity": "Common",
        "prediction": "When this card appears in the reading, you're being reminded that there is a time to speak, and there is a time to remain silent. Sometimes we want to speak without being able to be meaningful. This card tells us that now is an occasion for listening. Pay attention to what's being spoken to the spoken words but also to what is not said. By truly being in the moment and by truly listening, we can learn a lot - from what people say and also what they don't say. If you listen carefully, you'll learn valuable information and find clarity. This card is a sign that you'll be able to get better understanding of your situation or of a subject."
    },
    "A6Coming_Home": {
        "name": "Coming Home",
        "id": 37,
        "image": "https://gateway.pinata.cloud/ipfs/QmaEfDaESSoecQXmN3DmTrrdGB8bzU1DZ3thW8VQ7ywN88",
        "rarity": "Rare",
        "prediction": "The large and bright moon guides the woman back into the reassuring light of her home and castle. This card is about home, be it the space of silence and tranquility inside of us, or our literal home where we live. It's about finding rest and refuge in our own comfort with ourselves, during some silent and alone time, it's about finding safety and rest in our own home and space, it’s also about feeling at home with the people we love. Coming back home is often the best way to reboot and find ourselves again.  "
    },
    "A4Water_Of_Life": {
        "name": "Water Of Life",
        "id": 38,
        "image": "https://gateway.pinata.cloud/ipfs/QmVpgSCn2yPfi7umacHTJ5xfuFeyrfBJ81CoYoG4rtCWcx",
        "rarity": "Uncommon",
        "prediction": "A waterfall flows down the Gates of the temple with sounds waters rushing through the silence of the forest. This card is about regeneration and new beginnings bought by the water. All things that no longer have a place in your life, and that are no longer serving you can be flown away. Look into what you can let go, what no longer serves you, and what you no longer need nor feel connected to. By letting go, we make space for new beginnings to happen. And this card  is all about making space for those new opportunities."
    },
    "A23Inner_Desert": {
        "name": "Inner Desert",
        "id": 39,
        "image": "https://gateway.pinata.cloud/ipfs/QmWQU5H8BQMtTdnQi8NA6yfUCjByFKqjdDUXcABiu3PYok",
        "rarity": "Rare",
        "prediction": "The moon is half buried in the desert and the stars shine on the background. This card is about the inner desert, a place inside of us where everything is possible. It's a place of new ideas, and new patterns, of a boat that carries on our dreams, a place of darkness and brightness. This card tells us that if we take time, if we go slowly and if we listen to our heart and breath, we can come to a place of  flow: the inner desert. From this place everything is possible, it’s a place of seeds planting. This card tells you to slow down, so, you could be in a space of flow. This card tells you to let go of outside interference and noises and to find your inner desert within.   -  You may be surprised at what seeds could be planted and what new sprouts could emerge in your life."
    },
    "A10Voices_Of_Nature": {
        "name": "Voices Of Nature",
        "id": 40,
        "image": "https://gateway.pinata.cloud/ipfs/QmahPEpmn7Ryyi4jFsLWxXXEQ1VnHVe4rYXUMYZ6GokDqP",
        "rarity": "Uncommon",
        "prediction": "A lioness rises above the trees of a primordial forest. This card is about the voices of everything in nature: trees, rivers, animals - everything is alive, everything follows the rhythms of the seasons. If we slow down enough, we could feel the living pulse in the heart of everything in nature. And in turn it will teach us how we are part of nature too. This card also tells you that you are being supported in your life; that you have good and meaningful connections, be it your friends, family, teachers or guides in the invisible world. The universe and your loved ones got your back."
    },
    "A24Earth": {
        "name": "Earth",
        "id": 41,
        "image": "https://gateway.pinata.cloud/ipfs/QmRZoWBh6nsf8Zm32qrncHB8a2nJ3i9DMw4dPcBmu7iaUZ",
        "rarity": "Common",
        "prediction": "This card is about land and nature, about the Earth that supports us and on which our feet stand. It’s about the land that was the cradle of the first human civilizations. This card is about the land of our ancestors and the land that will nourish our descendants. This card is about fertility and connection and about the fullness of support from the Earth. In past cultures the Moon - as in this card - and the Earth were both linked to agriculture, and to the plenty of the land, those qualities were essential for the survival of our ancestors' agricultural societies. This card is about material well-being and prosperity whatever you are doing you are fully supported in your endeavor. "
    },
    "A13Coming_To_Life": {
        "name": "Coming To Life",
        "id": 42,
        "image": "https://gateway.pinata.cloud/ipfs/QmVgeBqv2CXA7bv7ANFon8AnDiSjVGFfXos3DWR6pTxQLo",
        "rarity": "Common",
        "prediction": "When you look back at your life, can you see how far you've come from? How much you've grown? Sometimes when we are in our everyday life, it's hard to track back and see all the small, accumulated progress we've made. But this card tells you that's you've grown, matured, and each small step led to bigger change, and doors opening too. This card indicates that a new path is beginning to sprout to life. If could be a long-held dream, a new important relationship, or a new learning opportunity that's going to deeply shape you. Your work has paid off. This card also tells you to be mindful to go with the flow, and not try to control the manifestation of this new path. This card tells you tohave trust, and to continue to show up and slowly work your way through. Then, you will be successful."
    },
    "A16Wind_Of_Change": {
        "name": "Wind Of Change",
        "id": 43,
        "image": "https://gateway.pinata.cloud/ipfs/QmdR1oKpSmr4zzrLyS286R4ZSr9wE8HPYR3JQyc3r9CpYG",
        "rarity": "Rare",
        "prediction": "It's in our human nature to yearn for the comfort of everyday life, for what we already know and to resist change. Even though we are not satisfied of our current situation, change is often more frightening. Yet everything in Nature - as we are part of Nature - is constantly evolving and changing, nothing is static, the seasons come and go, the weather and clouds are changing every minute. If there is one guaranteed thing: it's change. This card tells you that now is a time of transformation - you may feel uncertainty and discomfort, but whatever happens, know that winds will clear the land, and bring new seeds into fertile soil. This card tells you to anticipate change, and to welcome it when it's there."
    },
    "A38New_Opportunity": {
        "name": "New Opportunity",
        "id": 44,
        "image": "https://gateway.pinata.cloud/ipfs/QmRnfdD7V1ni8gc4zt3fKzJYSBBvbwzw4f53WP7rbkTZrQ",
        "rarity": "Common",
        "prediction": "A new and exciting opportunity is in the horizon. This card tells you that you have a new door that’s opening to you. But first you must let go. Endings come in many forms, and now is the time to stop holding on to what must go.... so that something better can take its place. Nature’s cycles include life and birth, but also disease and death. There is no beginning without an ending. And this card tells you: let whatever isn’t working in your life die and unravel, so you can step into something new and more promising. "
    },
    "A17Fate": {
        "name": "Fate",
        "id": 45,
        "image": "https://gateway.pinata.cloud/ipfs/QmUTERRceW15dg9ZejtAoYHhiAmMgoXv1FmChbbR4JdbGa",
        "rarity": "Epic",
        "prediction": "The ancient Greeks often talked about fate and the threes goddesses who weaved it: one weaved the beginning of our life, one weaved the middle and the events in our life, and one weaved the end of our life. Fate is about the possibilities and the talents that were given at birth to the Greek Heroes. But it's also the case for us mere humans. Our skills and talents were given at birth to us but we can always use them in the way that we want, we always have the choice of the \"how\". This card invites us to review our fate: our potential, our talents and our skills - and how we have used them. Did we build-on our skills? Did we work hard to yield the best results? Did we make the best of what has been given to us? "
    },
    "A4True_Knowing": {
        "name": "True Knowing",
        "id": 46,
        "image": "https://gateway.pinata.cloud/ipfs/QmVNMUzc29MkYz8Wr2a4ZZpn2rqviEAQxhZKASw5RevcEd",
        "rarity": "Common",
        "prediction": "The moon on the head of the deer lights up the landscape and the surroundings. This card is about deeper understanding. It's about connection and clarity. We all have this \"aha\" moment from time to time, usually when we are deep into and feel connected with what we are doing or experiencing. It could be our job, or when we are reading a book, or listening to a podcast etc. We just have this moment of clarity and understanding. It's not only intellectual understanding but a deeper feeling of being able to connect the dots. This card tells you that whatever new skill you are learning, or study you are pursuing, you are on the right path. "
    },
    "A36Time_And_Space": {
        "name": "Time And Space",
        "id": 47,
        "image": "https://gateway.pinata.cloud/ipfs/QmWB5xsJRRG2w1Lgc1ntHBVeK83L4rMTDWqy8xCQGhY9je",
        "rarity": "Uncommon",
        "prediction": "This card is about time and space, which are both component of our everyday reality and out of it. This card invites us to step a little bit back from our everyday life. If we close our eyes and put on the side our busy schedule and everything that's happening in our lives and around us, we take a step into this space. A Space that's the mirror and the backroom of our everyday reality. A space of stillness and silence where we can see more clearly about what our busy life really is about. This cards also tells us about the deeper connection of our reality, and if you are thinking about a project, this card is about your project having a deeper,  solid root in this space, and likely to be successful."
    },
    "A34Regeneration": {
        "name": "Regeneration",
        "id": 48,
        "image": "https://gateway.pinata.cloud/ipfs/QmV1PFemdKwSmYTciWBeJeUgFwrMXJz1ATJTSTTdpMv1vj",
        "rarity": "Uncommon",
        "prediction": "A woman swims  in a water cave full of trees. This card is about regeneration and second chances. Whatever opportunities you thought were gone, or whatever relationship you thought had no tomorrow can now be revived in a new way. Whatever you perceived as a failure will be given a second chance. This card tells you that you have a new opportunity with things you had to let go. At the same time, it also tells you to review your past action and to try to find the \"why\" of your past issues, and how you could do better. The situation is different now, and things have evolved, but understanding how and why it happened as it did will help you get more from this new opportunity."
    },
    "A24From_Above": {
        "name": "From Above",
        "id": 49,
        "image": "https://gateway.pinata.cloud/ipfs/QmYh5igxRNjpkVYE3Fvfmz2pDSuGqhMcSkcDekLNJ61k13",
        "rarity": "Common",
        "prediction": "When we soar above life's challenges and opportunities, new perspective becomes available to us. When we hesitate between two choices, rising above, and adopting a wider view will allow us to connect to more options. This card tells you that if you are clear with your thoughts, feelings and beliefs, you can rise above your present conditions to adopt a broader point of view. We think we are making novel choice every time, but the truth is, we always tend to choose similar options, and to come to a similar conclusion following a well trotted path. This card tells you that you have the opportunity to see the bigger picture right now. And this will open wonderful new vistas and possibilities you didn't think would be possible. "
    },
    "A31Wishes": {
        "name": "Wishes",
        "id": 50,
        "image": "https://gateway.pinata.cloud/ipfs/QmbGYFgDCYuy86icU2iYjj99b9RGqBViD6iXx6j6bUL5XN",
        "rarity": "Common",
        "prediction": "This card is a sign that the time has come for your wishes to be fulfilled and your dreams to come true. Part of the island is hidden: you've worked hard, and you were patient, the island has strong foundation as you have strong foundation underneath. And now it's time to celebrate, the lighthouse shows that you have clear direction, and opportunities are coming to you. With your strong foundations, all your hard work will pay off. The future  Is build on our actions and our choices, and yours will open up wonderful possibilities for you."
    },
    "0Strong_Foundations": {
        "name": "Strong Foundations",
        "id": 51,
        "image": "https://gateway.pinata.cloud/ipfs/QmbqLvrMZnzZj2SMhsozBC2YBp88GSoGeok9322rfkEJqF",
        "rarity": "Uncommon",
        "prediction": "This card tells you that you have strong foundations. You have everything that you need, the island in the picture is undisturbed by the storm. The same for you: you can be confident that your work, projects and relationships all have strong foundations. This card also nudges you to always reevaluate the situation, and ask yourself whether those building blocks that form your foundation are still strong and necessary in your life? You have strong foundation, but to be able to maintain it in the long term you will need to be flexible to change. This card tells you to be mindful about it, and also to be truthful to yourself about necessary changes.: as humans it’s easy and tempting for us to stay in what we know."
    },
    "A7Questioning": {
        "name": "Questioning",
        "id": 52,
        "image": "https://gateway.pinata.cloud/ipfs/Qmeo885XzBEnY1Tjvp4ezYmY2pj4zNs1apZ8BLt6DF68AL",
        "rarity": "Rare",
        "prediction": "This card is about our ability to think out of the box and to ask questions. We often have a way of thinking, acting and reacting to situations that are conditioned by our past, by what worked for us in the past, by our education and by the people around us. This card challenges us to think about the other facets of a problem. Are we able to be truly creative in our thinking process? We may want to try to put ourselves in someone else's shoes, this will often change our way of thinking. Opening the door for us to think in a different way than the way we are used to. Seeing different perspectives. And from this more \"open\" space, we can begin to think differently. As humans we often keep doing the same thing if it worked in the past, but everything evolves and we should too - this card challenges us to think in a different way, and to be more open."
    },
    "A16Imagination": {
        "name": "Imagination",
        "id": 53,
        "image": "https://gateway.pinata.cloud/ipfs/QmYy9JoRS4vMShbDnDkXcjG3e3ZnppGALmgbhcaF99vdDX",
        "rarity": "Common",
        "prediction": "This card is about imagination, and the creativity you have inside of you. It tells you that you have the creative stream flowing through you, be it ideas, words, pictures or visions. Creativity often flows in and out of us, we don't \"own\", it as it has its own rhythm and cycles. But some people are more sensitive to it, and are better conducts for creativity to flow through them. This card tells you that you are one of these persons. You can channel this energy in multiple ways, based on the skills you already have, and that you can use as supports for your creativity to flow. For example, if you enjoy writing, you can let your imagination flow through what you write. This card is about creativity flowing through us, and expressing itself."
    },
    "A28Treasure": {
        "name": "Treasure",
        "id": 54,
        "image": "https://gateway.pinata.cloud/ipfs/QmZ2qFwkg5iJDnYGyz81MQdDJq7HyqTnBMsEVJfP95mJDq",
        "rarity": "Uncommon",
        "prediction": "This card represents  opportunities, good fortune fulfilled and prosperity, it's an auspicious card. It may indicate material or emotional fulfillment, it tells you that your patience and hard work has paid off, and you're now ready to enjoy the result. This card also tells you that true wealth comes from who we are, and how we see our life. Are we happy and satisfied? Wealth has different definitions to different people - sometimes it's about meeting in the middle: if we manage our own expectations, it’s easier to appreciate our good fortune. This card tells us that prosperity is around the corner, and it's the result of our own hard work. True prosperity is often a combination of realistic expectation, hard work and luck. And not only luck or wishful thinking. This card also tells us to be grateful for what we already have."
    },
    "A14Dark_Goddess": {
        "name": "Dark Goddess",
        "id": 55,
        "image": "https://gateway.pinata.cloud/ipfs/QmaW8rScYy7JuxfEt3HheZrSJ53ZWo7e48XjB13tyZjgKH",
        "rarity": "Common",
        "prediction": "This card is about the Dark Goddess, she has  been feared but also revered for thousands of years. Her names were Sekhmet, Kali, and many more. She is linked to the underworld and to destruction. What is birthed should also have an ending, since nature is about cycle, nothing is static, everything eventually dies. This Goddess is about necessary endings. Endings that make space in your life and allow you to welcome new beginnings. This card nudges you to review your life: what is no longer necessary in your life, what does no longer serve you? What is at the end of its time and should be released? It could be a job, a relationship, but also emotions and memories. By truly letting go, we open our lives to wonderful new possibilities."
    },
    "A3The_Leader": {
        "name": "The Leader",
        "id": 56,
        "image": "https://gateway.pinata.cloud/ipfs/QmR4tuvEwHdAUaKktwFoW8vMRhT4vaetJtkfSp2pN517bD",
        "rarity": "Uncommon",
        "prediction": "A deer stands tall in the forest, carrying a bright Moon upon his head. The moon is large and seems to be heavy, but the deer stands strong and carries it in full. This card is about the Leader archetype. It's about someone who can take the tough decisions and be fully responsible for it. Being The Leader is about being fully responsible and accountable. This card could be about ourselves and the leader in us, this card talks about the qualities we have and/or could develop to become a leader. This card could also be about someone who is a leader in our lives, exhibiting those qualities and in whom we could trust."
    },
    "A1Choices": {
        "name": "Choices",
        "id": 57,
        "image": "https://gateway.pinata.cloud/ipfs/QmYAQPThdc2QSGriResUmNKR4RrKhpz77gzPu9tyZft2tD",
        "rarity": "Common",
        "prediction": "A colorful balloon flies above the clouds and the open sky. This card is about the choices we have. Life is an adventure, and often, new opportunities will arise that you won't know how to deal with. This is one of those times you're required to trust that this new phase will reveal wonders and valuable life experience. Whatever choice you are making you will be fine."
    },
    "A3Forest_Spirit": {
        "name": "Forest Spirit",
        "id": 58,
        "image": "https://gateway.pinata.cloud/ipfs/QmY42CJqtGbUgooHPQftEQExCmq91V11FmFYZdQEfBUjC8",
        "rarity": "Epic",
        "prediction": "Our ancestors believed that every place has a spirit looking after it or embodying it. Just as we have a consciousness, the forests, mountains, animals and rivers also have their own - albeit quite different - form of consciousness. This card is about authenticity and your own truth. It's about the underlying spirit or essence, whatever we call it, in ourselves. It's about this whisper, and inner voice that's always authentically you. If you have struggles, maybe you should ask yourself if the way you act or plan to act feels genuinely you? Or does it feel like you are being influenced by other people or our society? If you don't have any struggle, this card is about listening to your own inner voice."
    },
    "0Slow_Moving": {
        "name": "Slow Moving",
        "id": 59,
        "image": "https://gateway.pinata.cloud/ipfs/QmcRLTC6e3V1BDUVwBgLT5Bu36Q4SEhutjsKWhtLChqzve",
        "rarity": "Uncommon",
        "prediction": "A man slowly advances on his turtle, he doesn't seem to be in a hurry. This card tells you to slow down, whatever your inquiry is. It could be about a relationship, a job, or something else. This card tells you to move slower, and to look at the details. If you move too fast you may not see all the elements of the situation, you may not learn everything you need to learn, or you may not dedicate as much time as you should to a person or a situation. We often aspire to do more in less time, to be efficient and productive. But sometimes we rush through and the result could be unsatisfying. This card tells you to take your time to grow into your experience and go through it in a careful and conscious way: you will be happy with the result."
    },
    "A8Deer_s_Lair": {
        "name": "Deer's Lair",
        "id": 60,
        "image": "https://gateway.pinata.cloud/ipfs/QmVbmyBwL47nNXebDm5B2aGUKrWU9w65aTtJC7ND2RZ2zA",
        "rarity": "Rare",
        "prediction": "You have a great internal warning system that let you know when things are out of harmony, or when there is danger. This card says that you are about to enter an unknown territory - be it a new job, a new relationship or some kind of change in your life, and this warning system will help you with keeping dangers at bay. This card tells you to be aware of your surroundings and to be mindful of what you are doing. There may be danger and unexpected challenges coming along with these new opportunities but if you listen to your inner voice and warning system you should be fine."
    },
    "A17The_Fates": {
        "name": "The Fates",
        "id": 61,
        "image": "https://gateway.pinata.cloud/ipfs/QmfRyJB6jQkts9bKpvXR1kyE2MSzsRZZ633ycR6DzNbRQB",
        "rarity": "Common",
        "prediction": "This card is about things in our lives that we can't control, nor understand. We are part of the bigger weave of life, our fate enter-twined with the one of the lands on which we live, of our family, of our society and of everything around us. Nothing grows in isolation, and we too are part of the All. This card tells you that whatever happens it's part of something bigger on which you have no control. It may not be in your cards to understand why certain events happened in your life. This card asks you to accept it, sometimes it's pointless to try to find the cause to our problems. Sometimes it's no one's fault; it's just how it is. We can accept the mystery and work with those circumstance, trying to learn from them and to stay afloat. "
    },
    "A14Moon_Goddess": {
        "name": "Moon Goddess",
        "id": 62,
        "image": "https://gateway.pinata.cloud/ipfs/QmQNtUYun3BHj5NPTHayzpz4puFGdfM3ovhGZKZs2ZWCUL",
        "rarity": "Uncommon",
        "prediction": "The moon was linked to The Morrigan and to other Triple Goddesses. This trio of Goddesses is often represented by a maiden, a mother and a crone. They are linked to birth, life and death. This card is about the cycles and tides of nature. In nature nothing truly dies, and everything is recycled at the end of its life:  the waned plants become the compost in the next Spring. This card tells you that you are in a time of change: something ended and a new project, relationship or energy is entering your life. It’s an in between time, a time of transition between an ending and a new beginning. This card tells you that you should listen to your body and instinct and rest if you are tired. We live in a society which reveres efficiency, but this card tells you that it’s fine to be patient as you are in an in-between time. There is no rush and things often follow their own timing. There is nothing you can do. Your energy and cycle are yours alone, and self-care is of utmost importance."
    },
    "A6Asking_For_Help": {
        "name": "Asking For Help",
        "id": 63,
        "image": "https://gateway.pinata.cloud/ipfs/Qmbg1PB9yTya1EhWNVSPP5zRhNuoJuG4RrspzNZAmdYjrg",
        "rarity": "Common",
        "prediction": "Asking for help is difficult for most people – it is often more difficult than giving help. We may want to ask ourselves why? Is that our pride or our ego? Or is that we don't want to bother other people by asking something? This card tells you that if you find yourself in trouble, you can expect help. You need only to ask, and assistance will come your way. Giving AND asking are both part of life, and are building blocks for relationships with other people. Ask and you will receive. In life giving and also receiving are two sides of the same coin. "
    },
    "A10Encouragement": {
        "name": "Encouragement",
        "id": 64,
        "image": "https://gateway.pinata.cloud/ipfs/QmRY8JRVH1RPYFaTuhhAwzfVAEmJnF8hJvKZpFEYQF79xW",
        "rarity": "Common",
        "prediction": "This card tells you that you are being encouraged to step into your strength and power. You may be considering a new project, be it a new job or a new learning experience, and this card tells you that you have all the resources you need to be successful. The universe got your back and is giving you a nudge in the right direction. Everything you are doing is supported by the universe and powers larger than you. In one word, you should keep going!"
    },
    "A28Discover_New": {
        "name": "Discover New",
        "id": 65,
        "image": "https://gateway.pinata.cloud/ipfs/QmNR5y6SXkgPC3KHNRD9nimpJ2LTTfQTXZ2VMVq9HEgSLz",
        "rarity": "Uncommon",
        "prediction": "This card is about new discoveries and  knowledge. Wisdom comes from living our lives consciously, and not from reading about it. This card suggests that you've learned from the books, but now it's time to learn through experience and new opportunities This card tells you to change your preconceived notions about school and learning, and to be a student in every way of life, not only at school or by reading books. Embodied learning through experience is the kind of learning that's the most memorable, because we remember it through all the parts of us: body, mind and emotions. "
    },
    "0Treasure_Awaits": {
        "name": "Treasure Awaits",
        "id": 67,
        "image": "https://gateway.pinata.cloud/ipfs/QmZPMCNuZwiLXi9qMHAhxnHFYy4i63Le5LYmeW2LtgmHNN",
        "rarity": "Rare",
        "prediction": "This card represents good fortune, ambitions fulfilled and prosperity. This card could also indicate emotional well-being, or a time of happiness. This card tells you that even though the treasure here is seen as being outside of you, your true abundance comes from who you are, and how fully and honestly you are living your own life. This card tells you to be conscious of how you feel when your external needs are satisfied. Do you still want something? Are you fully satisfied? Are you happy? Sometimes our external successes aren't reflected inside of us. This card tells us to be conscious and to take care of our inner treasure and well-being. It is as important as ambitions fulfilled and prosperity in our daily lives.."
    },
    "A24Heal_The_Earth": {
        "name": "Heal The Earth",
        "id": 67,
        "image": "https://gateway.pinata.cloud/ipfs/QmZ3BGYEFTZp4wuBoKzJ2zJ1TrWtydz3J4FGEu7A3PdTVt",
        "rarity": "Uncommon",
        "prediction": "This card is slightly different from the other cards: this card is not about you, it's about the Earth. This card calls us to change our behavior in order to preserve the Earth. It calls us to pay more attention to our daily consumption habits and to live in a more conscious way. Our ancestors maintained a symbiotic relationship with the Earth. The people of the past learned to work with nature, to revere it and also to fear it. Nature can be giving as it can be dangerous and deadly. Our ancestors tried to establish a balanced relationship with Nature: they took what they needed, and only what they needed. So, they would still have food the next spring: if we harvest all the plants without leaving enough for it to grow back, there will be no more plants the next year. This card tells us to be mindful in our daily lives and to not be too greedy when we take from the Earth. So, there will be enough for the generations to come."
    },
    "A27Moving_Waters": {
        "name": "Moving Waters",
        "id": 68,
        "image": "https://gateway.pinata.cloud/ipfs/QmYtDdhUzRuhVEqSfvbKmcChvf8MfkBEXJN9MWSPxH2cmT",
        "rarity": "Common",
        "prediction": "This card tells you that your period of restriction and slow moving has come to an end, your vista is now fully opened, like the waterfall rushing down in splashes. It's time to move and to discover something new. It could literally be travelling and going to another place, it could be changing job, home, or  appearance. This card compels you to move forward: it's pushing you, like the water - you need to keep going. It's better to embrace the new vista and changes than trying to stand still in moving water. This card tells you to follow the flow and enjoy the new opportunities. Be like the water and flow with your life and what it has to offer."
    },
    "A5Stronghold": {
        "name": "Stronghold",
        "id": 69,
        "image": "https://gateway.pinata.cloud/ipfs/QmVqv9pgrLiip8Dy5inLWPWYGVJdhRyR8Yi4LRgMUwEhrq",
        "rarity": "Common",
        "prediction": "This card is about inner strength, about resilience and patience. This card is also about our ability to keep going whatever difficulties we meet in our way. This card is about you: you are stronger than you thought. Difficulties in life can be discouraging, and can also lead to a feeling of unworthiness. But you are strong enough to go through any tempest. You may want to ask yourself if the difficulties are consequences of your own actions? If they are teaching you something? As in the myth of Hercules, without challenge we won't get stronger. Difficulties can be a learning occasion for us. And this card tells you that you have all the necessary tools and foundation blocks to be able to learn and thrive during difficult times."
    },
    "A26Spark": {
        "name": "Spark",
        "id": 70,
        "image": "https://gateway.pinata.cloud/ipfs/QmTLyfJratKEW6jdTxLWRW4s7FvjRf8hgzPSndug7D8n8d",
        "rarity": "Rare",
        "prediction": "There is a spark of creativity in you, waiting to come to life. Allow yourself to be open to your inspirations: they will bring beauty and satisfaction in your life. Be it something small in your everyday life: like creating and cooking a cake, or a bigger creative project, this is the perfect time and place to give birth to a new idea, to begin a new relationship or to learn something new. You'll find joy in expressing and bringing life to your inner spark of creativity, and this excitement and passion may attract other people along the way. "
    },
    "A13Creativity": {
        "name": "Creativity",
        "id": 71,
        "image": "https://gateway.pinata.cloud/ipfs/QmfTqopmbnZjgu3nwdZNHDDM3yr22k4st4P5sDB35izGFq",
        "rarity": "Uncommon",
        "prediction": "A woman opens her heart, and moons and stars are birthed from it. This card is about the creativity and passion we have in our heart. What activity makes us feel good? What subject makes us feel satisfied and in peace? What feels natural to us? The creative energy flow through every one of us and expresses itself in different ways - since we are all different people: we all have different aspirations and skills. Through which skills and aspirations does your creativity express itself? This card calls you to explore and connect with your own inner creativity."
    },
    "A33Connection": {
        "name": "Connection",
        "id": 72,
        "image": "https://gateway.pinata.cloud/ipfs/QmW1qQLXF97KyLap8orBWWTTfsxZuDoG3DX3Ztq4UdENKz",
        "rarity": "Common",
        "prediction": "We are all meant to have connections with other people, be it our family, society and the culture in which we live. Sometimes we can feel overwhelmed, and also different or lonely, but it's important to see the connection between everything - we are part of the all, rather than being separated from it. The desire to belong is something we all feel as humans. This card tells you that there is a place of acceptance for you. Maybe you no longer identify with a group or a culture/community you grow up with or from your past, but you'll soon find like-minded people, with whom you can connect, share and still be truly yourself."
    },
    "A25Live_In_The_Moment": {
        "name": "Live In The Moment",
        "id": 73,
        "image": "https://gateway.pinata.cloud/ipfs/QmTbd9esyb8dgjQUfvHTaztdZRY48zpLUw98fSEAB3G6tP",
        "rarity": "Common",
        "prediction": "A woman floats on the water, she seems to be oblivious to time and to everything around her. When our head is in the past or the future, we run the risk of losing our ability to be fully present. The future has no substance nor existence in the present, it's only a \"what if\" and the past is made of our own memories. And memories can be treacherous, the past is similar to a story we are telling ourselves. Both the future and the past are stories and potentials, only the present is real. If we fully live in the present, we can build strong foundations for the future and heal our own stories we tell ourselves about our past. The \"Now\" is the most powerful place to fixate our attention. "
    },
    "A4Clarity": {
        "name": "Clarity",
        "id": 74,
        "image": "https://gateway.pinata.cloud/ipfs/QmPHb9Fyi3vak1cmrzh2FNYCQq2dyec9snrPY224mEEFCg",
        "rarity": "Uncommon",
        "prediction": "A large moon lights up a deep and dark forest with its airy and bright light. This card is about being able to see clearly. Sometimes in life we feel confused, and we are not confident or sure about our current path, or what we are doing. This card tells us that we have the ability to find our true inner purpose if we look inside, it's always been there, waiting for us. We need to ask the right questions: What does feel natural, going with the flow in our life? What brings up deeper joy? What do we enjoy building and improving over a longer period of time? This card tells you that you can find more clarity in your life if you look within."
    },
    "0Moon_Canyon": {
        "name": "Moon Canyon",
        "id": 75,
        "image": "https://gateway.pinata.cloud/ipfs/QmNfN4Mi7GpFiRVSHZF4TEmKU2NtYUgsDgswzEfHhwPVBT",
        "rarity": "Uncommon",
        "prediction": "This card indicates a time of calm and peaceful energy. There is time for action and time for rest. Nature has a cycle and seasons, time for birth and time for dying and pause before new beginning. As modern humans we think of \"growth\" as good, and we revere prosperity, but constant growth without pause doesn't exist in Nature. And this card tells us that now is a time for rest, so we could reinforce the foundations of our work, reflect on what we have done and connect with our close ones. It's not a time \"lost\" but rather a time for reflection, and it's totally fine to not be \"on\" all the time. "
    },
    "A38Oasis": {
        "name": "Oasis",
        "id": 76,
        "image": "https://gateway.pinata.cloud/ipfs/QmZyMR85XnyKH8SHC1tNiMGcUR3qvTkQdoMyXTQ4y64Bxp",
        "rarity": "Epic",
        "prediction": "This card is about connection and rejuvenation. A relationship, a project, a dream – something you had to put on the side and let go from the past is back into your life again. This is the opportunity to try again. The card also asks you to think about what you could do better this time. What could you change? This is an auspicious time to resume a past project, relationship, or something else you had to let go. This card is about rejuvenation in various fields of your life. "
    },
    "A20Purpose": {
        "name": "Purpose",
        "id": 77,
        "image": "https://gateway.pinata.cloud/ipfs/QmQ9ew3gcNs8zz6STGVNMtinN5vzmWXMcv3g5kovWwqWM5",
        "rarity": "Common",
        "prediction": "A black and multi-colored wolf stands in the desert and looks ahead with confidence. This card is about following our purpose. This card tells you that you have all the skills that you need to accomplish what you want to accomplish. Just like the wolf, being  fully focused on the purpose and end goal of your project with help you succeed. Where our focus goes, our energy skills and intent go. If you are focused, all of your energy and skills with be pointed at your goal as a laser and you will be able to use your full potential. This card tells you to be fully focused on your goal, so you will be given it every possible chance to succeed."
    },
    "A30Breaking_Free": {
        "name": "Breaking Free",
        "id": 78,
        "image": "https://gateway.pinata.cloud/ipfs/QmXQMBcdEuqa9wi6mMWzWi35MEic2SP9ZJiCz53vfp37pQ",
        "rarity": "Uncommon",
        "prediction": "This card is about being self-reliant. A little girl crossed a bridge alone, without fear. This card tells you that you are competent, and right now, other people around you may not have the same amount of resource nor could help you. This card tells you to be self-reliant in the present situation. It also tells us about boundaries: be discerning about people you associate yourself with and spend energy on. They don't have bad intent but they are asking you to help them when they could do things by themselves: they could learn from their own experience. Instead, they are taking your time. So, this card tells you that it's fine to say no, and to work or be by yourself for now: other people could be a distraction rather than a help. Also, when you have strong boundaries, you begin to attract people who mirror you: they will bring something to the table, and you will be mutually supportive rather than one person gives and the other receives."
    },
    "A5Protecting_Treasure": {
        "name": "Protecting Treasure",
        "id": 79,
        "image": "https://gateway.pinata.cloud/ipfs/QmZqUatpReEUbLCzB4KgBoczG2Xte6TwehJHwLzGb829sH",
        "rarity": "Uncommon",
        "prediction": "A castle stands in the midst of the night, floating above the desert. Whatever events are happening in the outside world, you are safe and guarded in this castle. This card is about protection that's available for you. It's also about you being a protective person, someone who's loyal and nurturing for the people you hold dear. This card is about being trustworthy and strong. You will be able to hold your inner strength and to stay standing whatever the situation. You're stronger and resilient, you  spent energy building your foundations and it's now serving you during this  turbulent time."
    },
    "A26The_Weave": {
        "name": "The Weave",
        "id": 80,
        "image": "https://gateway.pinata.cloud/ipfs/QmRD6w9VymDcdD2Q2vGU45LJaox6AAJmUs2qpByZuB4i3q",
        "rarity": "Rare",
        "prediction": "This card tells us that we are on the right path, we've worked hard with our skills, and now synchronicity is happening: we are harvesting your hard work. This card tells you that the tide has turned and now it's finally time to let go a little bit: you can trust the weaves to carry you forward. You've built the foundations and now you're seeing the result of your work. You can expect new people, new circumstances and new possibilities coming in your life. Everything feel like they are coming together, enjoy the result of your hard work."
    },
    "A12Wisdom": {
        "name": "Wisdom",
        "id": 81,
        "image": "https://gateway.pinata.cloud/ipfs/QmTNxTK7MVtLNBPNknAD511WjzEkn9vJpakaJm3tvzox5u",
        "rarity": "Rare",
        "prediction": "This card tells you that you're at a place in your life where you have the maturity, the patience and the understanding to study harder subjects. It may be going back to grad school, or university, or studying something that's of interest to you independently. We often need lots of self-discipline, motivation and patience to successfully study by ourselves. And this card tells you that you're ready to do it. If you are thinking about learning something new this it a good time. When we can admit to ourselves that we don’t know enough and when we strive to always learn more, we begin to walk toward true wisdom. \"The only true wisdom is in knowing you know nothing.\" - Socrates."
    },
    "A15New_Discovery": {
        "name": "New Discovery",
        "id": 82,
        "image": "https://gateway.pinata.cloud/ipfs/QmVTHqQh7cxUG1juTRogbHmcfRGhwQGh6w4LEDK4jnbbup",
        "rarity": "Rare",
        "prediction": "This card is about new technologies and new discoveries. It's about our human ability to build ever more complex solutions and systems based on what we've previously built. It’s  the excitement of new discovery and how it could better and improve our existing technology. This card is about the internet in general, but also about cryptocurrencies, blockchain, and new technologies. It's about the desire in us to evolve, and make our technology evolve with us. This card tells you that an opportunity in those fields is waiting for you. It could be a job, an investment opportunity, a networking opportunity, or a learning opportunity. This card is about exploring new possibilities in those fields, if you are already doing that, this card tells you that you're on the right path and you may want to continuing building on your existing expertise. "
    },
    "A8Field_Of_Dreams": {
        "name": "Field Of Dreams",
        "id": 83,
        "image": "https://gateway.pinata.cloud/ipfs/QmSsiwRniGgjn5xxMTpgcRtDEeDj2WBWeFEXXsD7CipWV7",
        "rarity": "Rare",
        "prediction": "This card is about our imagination dreams and creativity. It's about combining what seems to be illogical and very different together. Our imagination allows us to go beyond the daily logical and practical thoughts. This card is about letting your imagination roam free, express itself and make connections, however absurd they may seem at first. It could open the door to new ideas and opportunities. Trust your inner child."
    },
    "A4Destiny": {
        "name": "Destiny",
        "id": 84,
        "image": "https://gateway.pinata.cloud/ipfs/QmWNuJvRLrHFqNoeVWGA2C1cAraN34rmtELpANxwFc7j4s",
        "rarity": "Common",
        "prediction": "Destiny is one of the most used concepts in Antiquity - but what truly is Destiny? Do we have a choice? What do we truly want? This card is about connecting with our deeper purpose. If we want money - is it about money or about feeling safe and not lacking anything? Finding our deeper fear, and also deeper drive often helps us find what we are really supposed to do in our life. In the Greek myths the heroes often have a specific talent allowing them to fulfill their destiny – what is your talent, something you find joy in doing? Something that’s natural to you?"
    },
    "A30Small_Progress": {
        "name": "Small Progress",
        "id": 85,
        "image": "https://gateway.pinata.cloud/ipfs/QmdhmX9vwtqaK7wxGwTS583HHmDUMpScvz1ioXLejzC7fK",
        "rarity": "Uncommon",
        "prediction": "Even though it may appear to you that you've gone backward, or that you're stuck, this card tells you that you're still progressing. Sometimes when we are in the middle of something it's hard to see all that we've done. We have so many things to manage, and we work a lot with the daily grinding and the details, so it's difficult to take a higher view. But this card tells you that you've actually progressed to a higher place, and you can look down from where you are. With this bird's-eye view of your past actions and the beginning of your project or relationship, you'll be able to see how far you've come from. This card tells you that everything is fine, you've made progress, and you're on the right path. "
    },
    "A14Yin_Energy": {
        "name": "Yin Energy",
        "id": 86,
        "image": "https://gateway.pinata.cloud/ipfs/QmSbDsj7DsjbMbkX8YMrN9TkjahMQSQ1ofBUMnTrr7Dh4u",
        "rarity": "Common",
        "prediction": "This card is about the feminine principle of Yin - the other side of Yang, the masculine energy principle. Yin is about being receptive, slowing down and absorbing. This card tells you that now is a time of slowing down, gathering information and taking in cues. This card also tells you that you may want to take the passive approach: sometimes it's better to let things settle by themselves instead of acting and actively being engaged in the situation. This card tells you to step back, slow down, and truly observe the situation with all the details. You will gain clarity, while resting your precious energy, and often, things may even settle by themselves. If they don't settle, you'll gain useful information to help you take decision and act."
    },
    "A3The_Father": {
        "name": "The Father",
        "id": 87,
        "image": "https://gateway.pinata.cloud/ipfs/QmRMam5JF532SB5prsccD2kuQJCo1Yz76QhMdx5jSUwkBA",
        "rarity": "Uncommon",
        "prediction": "A large and impressive male deer stands tall in the midst of the forest. This card is about the father, someone who is the foundation of our lives be it genetically or in our everyday life. It's about the father in us, the one who wants to be dependable, trustworthy and strong. It's also about the figure of Father in nature: the ancient people linked this deer-like figure to the god Pan for example, or to the Green Man. Both represented the deep strength and the protective sides of nature. This card is about manifesting this power in your life - it could literally means being present to your father, or as a father, or it could mean being the steppingstone for someone we love, be the trustworthy and protective figure in their lives. Nature is full of archetypes - here is The Father one - we could learn from by incarnating them in our own lives."
    },
    "A4The_Tree_Of_Life": {
        "name": "The Tree Of Life",
        "id": 88,
        "image": "https://gateway.pinata.cloud/ipfs/QmVzuohZyqYBpePCg4PndJR5ekQYEiDcrcKeCbXepUWs9u",
        "rarity": "Uncommon",
        "prediction": "A lush tree full of light stands tall in the depth of the forest. This tree is linked to the Earth and to the whispers of ancient goddesses. Inanna - the Sumerian Goddess of the dawn of human civilization, carried the Tree of Life from the Heavens to the Earth. This card is about the deep nourishment of nature, the primal connection between our body, our cells and everything around us - made with the same cell materials. This card shows the connection and wisdom we can find in nature. It also tells you to observe better: there are answers to be find in the simplest and most common places. As there is wisdom to be find in nature which is everywhere. We just need to be able to truly listen and see."
    },
    "A36Time": {
        "name": "Time",
        "id": 89,
        "image": "https://gateway.pinata.cloud/ipfs/QmQJ5irwtf8wcsgMGPyBmmMZfJvRrYRqKCFKV1Mit4UZWR",
        "rarity": "Common",
        "prediction": "This card shows that, in some matter of importance, time is of the essence. You may have some delay in your work, or your relationship isn't developing as quickly as you wish. This card is about your actual timing not always matching your ideal timing. But this card also tells you that completing your project, or having a fulfilling relationship, is more important than being on time. Sometimes we are too wrapped into accomplishing steps in time, rushing to finish something we are doing so we won't miss the next opportunity. This card tells you that you have time, and there is no need to hurry. Your project may require more research and follow-through, your relationship may need time to mature. Breath, everything is going to be fine, and you're not \"late\"."
    },
    "A18Unfinished_Event": {
        "name": "Unfinished Event",
        "id": 90,
        "image": "https://gateway.pinata.cloud/ipfs/QmNVcmaDuhgYsM2EQ7u8HxVQasUQ2VzCXRqdARFAfuZRw2",
        "rarity": "Rare",
        "prediction": "This card tells you that you are ready to move on. This card is about the ending and unravelling of a project, event or relationship. Everything is in constant motion and nothing is static in Nature. And everything eventually comes to an end. This card tells you that you can move forward. But before, it's important that you come to term with where you are now, and accept the ending and completion of a cycle. You may want to check how you feel emotionally and if you have your closure and the answers you need. It's difficult to move forward if we are leaving unfinished emotions and thoughts. Reflect on the completion of your project and on what you've learned: there is always a silver lining in everything."
    },
    "A19In_Between": {
        "name": "In Between",
        "id": 91,
        "image": "https://gateway.pinata.cloud/ipfs/QmQMiiMjhgmGQAhgKozqWKtGtxVmzJVs8EN24sS3LGyHWe",
        "rarity": "Uncommon",
        "prediction": "This card tells us that we are in a in between space, it could be between two choices, between two paths in life or it could be a time of hesitation. This card tells us that this state is fine. We live in a fast-paced society and we often feel the need to decide right away so we could move on to the next step or the next big thing. This card tells us that now is not the time to choose nor to make a decision. It's may not be a comfortable space but we shouldn't be rushed into making a decision. This card tells you to wait, and you will have more clarity about the situation. There is no rush, and you're in a secure place: you can take your time."
    },
    "A35Being_Immobile": {
        "name": "Being Immobile",
        "id": 92,
        "image": "https://gateway.pinata.cloud/ipfs/QmNef9nsj9KGQNVHqN461vSamroFLk9A5McQjMAyijxMtw",
        "rarity": "Uncommon",
        "prediction": "A statue stands in the sand as if it's been there for a long time, still and immobile. This card is about being immobile and feeling stuck. It's not a comfortable situation, but sometimes the only way to move forward is to slow down and to look  clearly at the situation. When we are stuck, often a shift of focus is needed. Sometimes there is nothing that we can do, we need to reflect, pause and find joy in the small moments of our lives. By slowing down and seeing things with a less rushed and more open mind, we will be able to see the situation with more clarity, and to come to a form of understanding.  This, combined with the natural tides of the situation - everything comes and goes - will allow us to soon be out of the muddy situation, and back into action."
    },
    "A13Joy": {
        "name": "Joy",
        "id": 93,
        "image": "https://gateway.pinata.cloud/ipfs/QmSVGg5AT5ve7h1sbWmvet4ecZMAGgnJtctbte4cX6rnyw",
        "rarity": "Common",
        "prediction": "This card is about feeling joy and excitement - no matter what your inquiry is, you will be happy with the results, you have worked hard and you're coming to a sense of fulfillment and satisfaction - it could manifest as material result and/or as the feeling of closing a door, and having done well. You have done the best you can, and you have every right to be happy, and satisfied that your hard work yields result. This card is about connecting with this deeper place of peace and satisfaction in us. This card is about connecting to joy that is not linked to external validations: be it your surroundings or the people in your life. This state of joy is linked  to your own inner feeling of having done well, and being fully happy with what you've accomplished. "
    },
    "0Dry_Desert": {
        "name": "Dry Desert",
        "id": 94,
        "image": "https://gateway.pinata.cloud/ipfs/QmbSNK9sYcHzDb3WTnd4VW7UEXpt2rDfj721QXi68LxcJr",
        "rarity": "Common",
        "prediction": "This card tells you that your question is barren and can't give you anything right now. Whatever your question is, there is no substance to it. You may have seen something or some possibilities in the situation, but it's not supported by facts. This card tells you no matter how beautiful the mirage is, it's only an illusion. But knowing that is the first step to let go, and it's the message of this card. Knowing it's not the right place, opportunity, or person, and be able to let go, so you would be able to welcome something more fulfilling and more harmonious into your life."
    },
    "A4Solitude": {
        "name": "Solitude",
        "id": 95,
        "image": "https://gateway.pinata.cloud/ipfs/QmdmhE7LKTnsw2bGNyK9SxNJFM77PFkTXVr3P7vfjJHPgd",
        "rarity": "Common",
        "prediction": "This card is about going inside, emptying our mind and allowing stillness to flow through our whole being. This is not a great time to begin something new, rather it's a time of conscious retreat. You may want to step back a little bit and take your focus off your immediate life events: sometimes things settle by themselves. Sometimes we are too entangled to make clear decisions anyways. And it's also an opportunity for you to connect with more clarity and to recharge your energy. In this way you will be able to come back stronger."
    },
    "A34Sacred_Waters": {
        "name": "Sacred Waters",
        "id": 96,
        "image": "https://gateway.pinata.cloud/ipfs/QmRoNunKMFLV5ehWQFozBGeGHycX6WLrrBqN16vC1kGUAY",
        "rarity": "Uncommon",
        "prediction": "This card is about being able to see ourselves and to love what we see, it's as simple as that. This card is about self-love and self-appreciation. The sacred pool reflects to us who we are as a whole: body, emotions, thoughts and instincts. It also reflects our past and future: it's a magical pool that reflects the whole pattern of us as a living being. It invites you to look into yourself, to see how far you've come from, to see the joy you've experienced, and the learning you've gained. You can also see the beauty of who you are, you are whole: the child, the adult and the old man/woman. You can see every part of you from all times. This card gently invites you to see beauty and Divinity within you.. The Sacred Waters card invites you to be truly kind and loving with yourself, to see yourself for who you are: beautiful, vulnerable, and above all, as someone who did the best with what he/she had."
    },
    "A5Storms": {
        "name": "Storms",
        "id": 97,
        "image": "https://gateway.pinata.cloud/ipfs/QmfGppjQBGGNp1hZF3uxGwWcR87DDpvBxt4tDowCVog96y",
        "rarity": "Uncommon",
        "prediction": "An uncomfortable chaos is surrounding you: things that you have left unattended and have ignored are coming to the surface and may cause conflict, stress or other uncomfortable situations. But do not worry too much, sometimes it's just the building up of energy needing release. As a buildup of storm must break. Storms can be destructive but also cleaning. Something maybe stirring up in you, in your life or around you, and the release is necessary. You may want to let the storm and its destructive power flow through and be patient, try to observe in stillness instead of being engaged in it. It's a temporary situation, the sun will shine again, and new sprout will be observed from the cleaned ground. This card is about a temporary uncomfortable situation that will come to pass."
    },
    "A16Finding_Direction": {
        "name": "Finding Direction",
        "id": 98,
        "image": "https://gateway.pinata.cloud/ipfs/QmTqYWLTsL9TMCGR77xCsqsGGZEDUEStxgVEa5qFLM6nZN",
        "rarity": "Common",
        "prediction": "This card tells you that you're  in the right direction. You may have doubts about a situation in your life, be it a relationship, a work situation or something else, but this card tells you that you are on the path  that's right for you. It may not be easy, this path maybe longer and more difficult than what you have thought, but this card tells you that the result will be worthy of your efforts, and that your personal learning from this experience will be fruitful too. This card generally reassures you in your choice: whatever your doubts are, you will see results if you keep going in your present direction."
    },
    "A27Mending": {
        "name": "Mending",
        "id": 99,
        "image": "https://gateway.pinata.cloud/ipfs/QmdKkMB1oXzy9jwtkbw1iniuffvusPcXid2fwAZsHufqww",
        "rarity": "Common",
        "prediction": "Each time we are hurt and we allow the hurt  to pass through us without understanding and integrating it, we accumulate it. The burden slowly becomes bigger and bigger, and our painful past experience and memories shape our behavior. This card tells you it's time to let go, time for mending rifts, and healing wounds. The old wounds and memories become burdens that no longer serve you, and those memories are shaping your behavior as emotions and instinctive reactions, be it fear, anxiety or something else. Now it's time to forgive and release, make peace with yourself and others. Those energies from the past no longer serve you, they are rather trappings, you should set them free."
    },
    "A35Deep_Rest": {
        "name": "Deep Rest",
        "id": 100,
        "image": "https://gateway.pinata.cloud/ipfs/QmYqYfg6x3woa5PWHGZQtVMZuzKyQNR3zZMWLj5UyKhtdJ",
        "rarity": "Uncommon",
        "prediction": "This card tells you that now is a time to step away from what you are doing and to rest. It tells you to act similarly to the statue, just be and let the rest flow around you. Let the care of your surroundings go. This card tells you that it's a time of non-action, and to allow yourself to step back from goals, to-do lists and wants. There will always be new things we want, and new action to be taken in our to-do list. This card tells you that moments of detachment are important. Stressing out and being busy all the time can block us from our deeper creative power. In a place of rest, you may have better ideas surface, or better decision taking skill."
    },
    "0Rest": {
        "name": "Rest",
        "id": 101,
        "image": "https://gateway.pinata.cloud/ipfs/QmShmAiCQ9Go3RWYCvbCqittX7i54N7xHzLoz7eD3tXmvN",
        "rarity": "Uncommon",
        "prediction": "A woman is peacefully floating on the water - she doesn't seem to care about her surroundings. This card is about deep rest and relaxation. It tells you that even though you have things to worry and think about, work to finish, and responsibilities to deal with, you have to take a serious rest. We strive to be competitive and to perform in our societies, and we sleep – but do we sleep enough? Does our body have enough rest? ? This card tells you to let go of immediate worries and have a good rest - perhaps cut off from the internet, or sleep earlier and meditate before sleeping. Sometimes our body needs to fully rejuvenate and deeply rest - it is like a reset. And often the best answers and our best performance come after resting."
    },
    "A20Deep_Knowing": {
        "name": "Deep Knowing",
        "id": 102,
        "image": "https://gateway.pinata.cloud/ipfs/QmVRgtkbMDwb5T2MuXPH8JrTFz2MCGzugnR6uSHZ3fDaLh",
        "rarity": "Common",
        "prediction": "A wolf stands in the desert carrying a pyramid on his back. Wisdom and knowing are often about being observant and remaining neutral until we truly know what's going on. We often act impulsively, or because we \"feel like\" that we should act and do something, or because our society tells us that we should act in some circumstances. This card tells us to adopt an observer position which will give us a clearer perspective. Before being able to understand something, we need to truly see it, without emotions nor outside influence. This card also tells us that things come and go, rarely things are personal: life is a continual flow of experience, and by stepped back and out of the action, we can truly understand the situation better."
    },
    "A14Hidden_Treasure": {
        "name": "Hidden Treasure",
        "id": 103,
        "image": "https://gateway.pinata.cloud/ipfs/QmdLV6T4v3aW98FhbyippoZq8pWkjQyPyAhMCT5GnDiKxq",
        "rarity": "Common",
        "prediction": "At this time, you can be confident that success is yours, especially if your question is about a job, a business, and anything related. This card is about the Earth element which is linked to fertility and growth. As the Earth nourishes our physical body, this card is about the nourishment and growth of everything financial in your life. This maybe a good time to sale something, to begin a new business enterprise or to begin a new creative project. You will need to act on it too, and work for your goal to be fulfilled: the Earth element is also about everything concrete: so only dreaming about your project won't be enough. Growth takes efforts and hard work. "
    },
    "A4Surrender": {
        "name": "Surrender",
        "id": 104,
        "image": "https://gateway.pinata.cloud/ipfs/QmfUqZELxXmz9nQfCaZHs74eLWi7erLixEiPpwqNAZRSxB",
        "rarity": "Uncommon",
        "prediction": "A goddess stands tall in the midst of the forest. She is full of beauty and strength. This card is about power that are bigger than us, this card is about the powers of nature. The deities in the Egyptian Pantheon were called \"Neters\" which means \"Nature\", deities were seen as immense power of nature herself. This card is about trusting and surrendering, not going against those powers but flowing with it. It's about being flexible when we are stuck, and trusting when we don’t know what’s really happening. Sometimes there is no point to go against the flow."
    },
    "A29Let_Go": {
        "name": "Let Go",
        "id": 105,
        "image": "https://gateway.pinata.cloud/ipfs/QmenGL76coMSTimju8ntc5VpcUTJCc5T2BhCSVrAZ3V41Y",
        "rarity": "Rare",
        "prediction": "A mysterious door stands on the beach, but can we access it? This card is about letting go of what we can't have. There are times in our lives when it appears that no matter how hard we work and how deeply we desire something, the result we are seeking will be impossible. It's as if we don't truly \"get it\" or as if something does not fit in. People will often call it bad luck, or think that they haven't done enough. But this card tells you that right now, it is not the path for you, and if you follow this path, indeed you may not see results. This is a time to accept that not everything is available for us, or good for us, no matter how much we desire it. Sometimes it's much better to let go, so we will have the space for something new to happen."
    },
    "A31Leading_The_Way": {
        "name": "Leading The Way",
        "id": 106,
        "image": "https://gateway.pinata.cloud/ipfs/QmZko1sjU1QBkmJYZr84hyGqvTWdSMkXAYe819Ar9LixQA",
        "rarity": "Common",
        "prediction": "Whatever kind of opposition and turbulent atmosphere are in your life, be it relationship, job, or just the society in which your live: you stand in a solid way. The card tells you that you can use the destructive and turbulent flow of energy to let go of what no longer serves you: let them be scattered by the winds. Letting go of whatever you no longer need allows you to reinvent yourself. And this card tells you that you are strong enough to go through any storm or chaos in your life or around you. You may face difficulties you can't control, but you will be able to stand your ground. The chaos will pass and lead to reordering of things. If you are able to let go of what you no longer need with the winds of the storm; you will be able to renew yourself after it."
    },
    "0Island_Of_Treasure": {
        "name": "Island Of Treasure",
        "id": 107,
        "image": "https://gateway.pinata.cloud/ipfs/QmPq7VzSdVAkGHKk1TvU3BXGgyZFQRsrb2iPUDBWdTAz7h",
        "rarity": "Uncommon",
        "prediction": "This card tells you that you've worked very hard and cultivated resilience, now it's  time for harvest. You've hit the jackpot; you have come into alignment and good fortune in your life. Your own path and hard work are crossing path with great opportunities. This card tells you to open your eyes and to be present: by doing so you will be able to spot the new opportunities, the way they appear in your life may not be always apparent. This card tells you to see all the angles and the long-term potential of propositions and opportunities that come to you."
    },
    "A28Small_Pieces": {
        "name": "Small Pieces",
        "id": 108,
        "image": "https://gateway.pinata.cloud/ipfs/QmddPYdUHQVhwLgruuwfowe1xQ2UpbjjRr6HagN7R6vFM3",
        "rarity": "Uncommon",
        "prediction": "This card is about the details, and being able to see them - even if they are really small. This is the time to be aware of the details in every area of your life. When this card appears in a reading it's a sign to be more detail oriented on a project, and to make small but meaningful changes. We do that by being aware of small details that are important. It could also be by being nice and adding small personal gifts or touches to people we love: they will really appreciate it. This card could also be a warning: there are things in your life you should put more attention on: it could be your taxes, your money, or the people in your life. Something maybe hidden and by being able to look for and catch the small details you'll gain clarity and transparency about the situation. You will then be able to act on time."
    },
    "A21Rock_And_Mountain": {
        "name": "Rock And Mountain",
        "id": 109,
        "image": "https://gateway.pinata.cloud/ipfs/QmdjMRnHszL8cbRTxSNJf6E1vYFaWyZCEgkZv9gzgt6NkF",
        "rarity": "Rare",
        "prediction": "Sometimes we'll come across what appears to be insurmountable obstacle - like a large mountain or a big boulder rock. We would then ask ourselves \"what do I do? Do I climb it? Do I try to find a way around it? Or do I give up?\" This card tells you to be patient and observing: a way around the mountain will be revealed. Trying to climb it and taking the risk of falling from it isn't always the best way to go. Ass in life, if we take the time to look at a situation under multiple angles, we are often able to find a less dangerous, easier and better way around an obstacle. This card tells you to remain open to new possibilities, to observe calmly instead of being stubborn and taking unnecessary risks."
    },
    "A9Following_The_Leader": {
        "name": "Following The Leader",
        "id": 110,
        "image": "https://gateway.pinata.cloud/ipfs/QmVsiVM8ZpuFkxXydAKF3zGaVDJe9A9pKvoQi2FNYaFTdY",
        "rarity": "Uncommon",
        "prediction": "This card is about someone in your life who is trustworthy, reliable and hard-working. Whatever is your question you can trust this person. This person could be a family member, a partner or a boss. You can follow this person's advice, or ask for them. If you need a recommendation, you should ask for one too. This person may seem strict - but this trait goes along with their stable and reliable personality. Whatever happens, you can count on them. This card could also be about you - and you are the trustworthy leader, in this case know that you are a good leader and that you are important to the people around you."
    },
    "A25Letting_Go": {
        "name": "Letting Go",
        "id": 111,
        "image": "https://gateway.pinata.cloud/ipfs/QmfGa7aBB4PYYinZig1NFqyadzfbcsL2AsBWT29qvRiEEf",
        "rarity": "Common",
        "prediction": "Do you feel like you are tired, bogged down and can't always think straight? This card tells us that if we don't let go of our emotions, thoughts, and even physical clutter in our homes, we can be overwhelmed. Energetically we are maintaining the space for all those objects, emotions, and thoughts to live within us and all around us. All the resentment, comparison to others and regrets takes up energetic space. Every object you own means that you are maintaining it and those objects also take up your physical space. This situation can be overwhelming and tiring. This card tells you that it's time to clean up and to let go. It could be literally cleaning house or cleaning your emotional house. So, you can free space and energy for new opportunities to come into your life."
    },
    "A21In_The_Middle": {
        "name": "In The Middle",
        "id": 112,
        "image": "https://gateway.pinata.cloud/ipfs/QmTMSL17gKKpwzhmcJB9pG5NUna6HXMQmdoraygN43Y1pS",
        "rarity": "Rare",
        "prediction": "Sometimes things can seem a little bit unsettled, and it feels as if we are trying to find balance between too many things. This card tells us if we feel imbalanced and potentially tired and anxious because we are dealing with too many things, we need to reevaluate our priorities. What is urgent and what is not? Can we delegate something? Can we simply let something go, as if it won't have any meaningful consequences in our lives when it’s gone? This card tells you to make changes, instead of trying to jungle too many balls. If you feel more stable and focused you will also be more successful in everything that you do."
    },
    "0Have_Faith": {
        "name": "Have Faith",
        "id": 113,
        "image": "https://gateway.pinata.cloud/ipfs/Qmamu1xsjoTnYfDbh4hPXcckXcTpR238hAQT3sG5fmp3xZ",
        "rarity": "Rare",
        "prediction": "This card reminds you that you are more than a person trying to get ahead in your life, and trying to make things happening for you and people around you. You are an expression of a higher power - or Divinity manifest - and this card is about faith. The forest behind the symbol reminds us that Divinity manifests in everything, especially in the balance of nature: in nature the cycle of life and death flows harmoniously - what needs to be decomposed is decomposed and new sprout comes to life each spring. Whatever your question, this card reminds you that you are connected and that your prayers are heard. This card also tells us that we can find Divinity in nature, in our everyday life. Wewe may want to go outside for a walk in the park or the forest and connect with everything around us."
    },
    "A4Slow_And_Steady": {
        "name": "Slow And Steady",
        "id": 117,
        "image": "https://gateway.pinata.cloud/ipfs/QmVa2bXS7eR7m7tbxDpw1zwccJRM5BGu3N6aSzn9W9pijK",
        "rarity": "Common",
        "prediction": "Remember the old cliche \"Slow and steady wins the race\"? This card is about slow and careful movements, unhurried steps and a steady heart. The deer carries a seemingly heavy moon but is undisturbed. Trusting life and taking a slow pace will allow us to save our resources and energy, so we could go to the end. Whatever your projects are right now, however stressful and quick-paced it may seem, find the inner pace inside of you. So, you could enjoy your journey while taking care of your body, and having all the resources to finish it. This card is about something that may seem urgent but is actually not: always listen to your own rhythm & pace."
    },
    "A13Magical_Stream": {
        "name": "Magical Stream",
        "id": 115,
        "image": "https://gateway.pinata.cloud/ipfs/QmSRXA838KKCJbLptrbskNumZUvfWuhiATYnR5nmicKqxx",
        "rarity": "Uncommon",
        "prediction": "Waves of light sprout out from the woman's open chest. This card is about magic in every one of us. Everything in nature follows cycles: birth, life and going into ending. The same for the seasons. This cyclical flow through time is magical, when we follow it instead of going against it, things are easier. This card is about listening to the cycle of nature and of our own body so we can  follow our flow. A simple example: how do we feel in our body? Are we tired? Is our body sensitive to the change of season? Do we tend to feel tired during seasonal changes? If it’s the case, maybe you can  simply  sleep and rest more during those times. This card tells you to flow with the energies and cycles of nature instead of against it."
    },
    "A22Courage": {
        "name": "Courage",
        "id": 116,
        "image": "https://gateway.pinata.cloud/ipfs/QmY7HTGLZ9vBNq9dDHe3fH4DTf2MdXFmijjkTZDf17VcND",
        "rarity": "Epic",
        "prediction": "This card tells you that you may be about to enter a dangerous path - so tread carefully and be aware of  what's happening in your life. This part will challenge you but it will also be exciting. This new experience will test your courage and endurance, and also your ability to keep going. The choice is yours: you can take the risk or you can let the opportunity go. If you choose to take up the challenge this card tells you to listen to our instincts and your inner warnings, we often say \"do not fear\" but instinctive fear is also a part of our inner warning system. This card tells you to listen to your own body and to follow your instincts, they will help in keeping you safe and on track in your new challenges and discoveries."
    },
    "0Ancient_Tree": {
        "name": "Ancient Tree",
        "id": 117,
        "image": "https://gateway.pinata.cloud/ipfs/QmXA2NCxr2mAdwJFrvMRi7uCw4SE7o1SJkcqD5NkRQjg1D",
        "rarity": "Rare",
        "prediction": "This card shows a tree that looks dead, but this tree is still standing in the midst of the desert, under a bright moon. This card is about the wisdom of the Ancient Tree. For people around the world, trees are linked to their ancestors and the memories of the land. Trees live longer than us, and their roots dig deep into the Earth. This card tells you that you have deep connection with your ancestors and your family. Family dynamics aren't always easy but this card tells you that you're supported, loved and part of a strong lineage. Whatever short term events or family dynamics, this card hints at a deep connection."
    },
    "A4True_Magic": {
        "name": "True Magic",
        "id": 118,
        "image": "https://gateway.pinata.cloud/ipfs/QmXaKkkeUJRTVncFosZQZSDZWt9nHnCwB9NDPXtuG3RjzG",
        "rarity": "Epic",
        "prediction": "A magical and mythical animal stands tall in the forest, the stars shine behind him, and he looks deep into your eyes... This card is about the magic of nature when we find silence and stillness within, so we could listen to it.. As we, human, are too part of Nature, this card is about finding magic within us and all around us. Move more slowly, and take your time, truly look around you, look in your home, in your backyard or on your balcony, consider everything to be alive. What do you see, feel and hear? It's about finding this deep space within us that's connected with everything around us: feeling the magic of being alive."
    },
    "A37Heart_Of_The_Jungle": {
        "name": "Heart Of The Jungle",
        "id": 119,
        "image": "https://gateway.pinata.cloud/ipfs/QmdZmJaovG1yYDc38Ep5JijMX5hYHYpZzeDhznhCjXzrv3",
        "rarity": "Rare",
        "prediction": "This card is about the silence and stillness in the heart of nature. And also in our own heart. We are a reflect of all that is alive, as Nature reflects in us. If you are unsure of your choices, just slow down, put aside the noises around you, the thoughts of events you can't control, the problems of other people in your life. Take some deep breath, then ask yourself \"how do I feel?\" \"Is there a side of the situation I can't see?\" Sometimes we are drowning in noises and the best way to find clarity is to slow down. This card is also about connection with our inner guidance. And asks us to take some time off."
    },
    "A4Prayer": {
        "name": "Prayer",
        "id": 120,
        "image": "https://gateway.pinata.cloud/ipfs/QmYBXRNgWTcxTboRD4LmkBAqkTMZYaiq44MRGjHV7GtTEQ",
        "rarity": "Epic",
        "prediction": "A woman stands in the midst of the forest, she looks at the light in her lap, full of love and compassion. This card is about our prayer being heard, it's as simple as that. This woman represents the Earth herself, the feminine, holding, vessel energy of natural that's link[GP2]ed to physicality and thus present everywhere. All of nature is part of her, and she's got your back. She will be protecting you and supporting you on your path. She won't protect you from your own errors: it's the only way for us to learn as humans, but she will protect you from outside interference and dangerous situations. She will also help you pick yourself up if it’s necessary."
    },
    "A23Find_The_Way": {
        "name": "Find The Way",
        "id": 121,
        "image": "https://gateway.pinata.cloud/ipfs/QmUSs9w2gjm3HUtp7LzPDKa52Z3DFnLFJWNRquSD7WJy6b",
        "rarity": "Rare",
        "prediction": "A boat navigates through the desert and seems to be lost. Or it's merely taking a rest before going on. This card is about finding our way through  life and in everything that we do. Life can be similar to a desert landscape: sometimes we encounter sandstorms and sometimes the sand dunes hide the future from our sight. But if we take the time to go inside, into our inner silence and to stay in it without outside disturbance, if we let go of electronical devices and noises, if we focus our intent on our heartbeat, we may hear our inner voice of clarity. What do we truly want? What make us \"feel\" something?  What should we do next? This card is about your ability to find the way whatever the circumstances: you will be fine."
    },
    "0Manifestation": {
        "name": "Manifestation",
        "id": 123,
        "image": "https://gateway.pinata.cloud/ipfs/QmW14YzL3aLxFCqXfHKZR69NynvUmq9QojBWiwzq9L3qhr",
        "rarity": "Rare",
        "prediction": "The circle is about completion. This card tells you that you are in a place of inspiration and balance, from this place you are at your most creative now, and whatever you apply yourself to will lead to the fruition of your dreams. It's a time of manifestation and potential. You may have to work hard: the mountain in the circle tells you about effort and hard work climbing it, but you will be happy that you did, your effort will be well compensated. This is an auspicious card."
    },
    "A28Wide_Open": {
        "name": "Wide Open",
        "id": 124,
        "image": "https://gateway.pinata.cloud/ipfs/Qmf7ErJtdrrXGHLcX7PvcjdvYD1MwzGvTGw8PX5n7ZTFDC",
        "rarity": "Uncommon",
        "prediction": "This card is about opening the door to new possibilities. Sometimes in life we are stuck in our ways of doing and seeing things. We often have our own way of taking decision and seeing the world, which is conditioned by our family, our own past experience and the society in which we evolve. Once we understand this, we can ask ourselves the question: are we really stuck? Or it’s our way of doing and seeing things that is no longer helping us. Maybe there are other possibilities out there, and we just need to change the way we see things. And adopt a more open decision making and thinking process. This card nudges us to be more open so we could see hidden possibilities."
    },
    "A4Magic_Stream": {
        "name": "Magic Stream",
        "id": 124,
        "image": "https://gateway.pinata.cloud/ipfs/QmXvXqEb1x3eS3eWNuMMRee9FWSRKoixtgMMchBvzxG9KY",
        "rarity": "Epic",
        "prediction": "Every event in our lives has its own beginning, middle and ending - however we shouldn't see them as separated from one another, since all those steps are part of the cycle and continuity: they form a whole. The same happens for the Magic Stream, our experience is filtered through our own body and past experience, we are limited by who we are as a human. That's why inspiration, new inventions and revelations rarely come from just one person. Our own experience flow with the ones of other people, forming a collective stream of information, experience and inspirations. It's a shared potential storehouse. When we remember that we are part of the continuity of life, and collective consciousness, we work within it - instead of being alone in our own little corner. We become more connected and we let the collective magic flow through us - as we fuel the collective too. Everything is connected. By sharing with other people, we also serve ourselves."
    },
    "A32Hidden_Secret": {
        "name": "Hidden Secret",
        "id": 125,
        "image": "https://gateway.pinata.cloud/ipfs/QmQx5A7Ew1cSQ7CVjpwiuGr8Jgn4ZndG8RpRFXRswSic3e",
        "rarity": "Common",
        "prediction": "This card is about a secret in our life. Something is out of our sight and hidden from us. It could be something that's important, but it’s not always the case. Sometimes a secret is important in the eyes of the person holding it, but it doesn't mean it really is for you, nor it will have consequences in your life. This card nudges you to look into the details, you may have missed some cues and information. This card also asks you to be open and be ready to listen: someone may confide their secret in you and will need your help. "
    },
    "A9Strength": {
        "name": "Strength",
        "id": 126,
        "image": "https://gateway.pinata.cloud/ipfs/QmfRWxNT6tRauekbCHWrk8v522j5k161VrnpFgeeuBJtPa",
        "rarity": "Rare",
        "prediction": "A turtle carries a heavy tree and the moon on his back. This card is about Strength - true strength that is linked to  resilience and to the ability to always keep going, slowly and one step after the other. The turtle is slowly moving but each of his step is steady and strong:  he is building for the long term. This card is about the efforts we put on in our lives to always keep going, whatever the difficulties are. Slow and steady work will help you  get the results you want. "
    },
    "0Stormy_Night": {
        "name": "Stormy Night",
        "id": 127,
        "image": "https://gateway.pinata.cloud/ipfs/Qma8ZYj2tFH81WKmcSvacCzdXtNfvWG7jMKDgGb5vMjWgD",
        "rarity": "Uncommon",
        "prediction": "Everything in nature comes into cycle: of birth, life and destruction. Storm was seen by the ancients as carrier of destruction - like Lilith the sandstorm goddess who brings ending. But ending also means new beginnings. This card is about the potential storms in your life, which could manifest as struggles, oppositions or tensions. But you have the ability to stay stable, in balance and in the center, without being pulled into those events. Then you will be in the eye of the storm, instead of being carried away from it. And as the cycle goes, those events will come to an end, and you will be in the right place for a new beginning and planting new sprouts."
    },
    "A33Commitment": {
        "name": "Commitment",
        "id": 129,
        "image": "https://gateway.pinata.cloud/ipfs/QmeCRtAQs76BVjDNiYuYzZsj8vM2pLpVTWurQNq9s5rF2R",
        "rarity": "Uncommon",
        "prediction": "This card tells you it's the right moment to commit, and to enter further into a relationship. Partnerships and relationships forming at this time could yield remarkable things in the future. But this card also tells you to be prudent and conscious of what you are committing to, and for how long. Commitment could be exciting but we shouldn't forget to be levelheaded. It's important to be clear about what we want out of this relationship and what the other person wants - are both compatible? And are we ready to commit to it for an extended period of time? It's hard to walk into a commitment, but it’s also hard to walk out of it. This card tells you that it’s important to have the whole process thought of beforehand."
    },
    "0All_Together": {
        "name": "All Together",
        "id": 130,
        "image": "https://gateway.pinata.cloud/ipfs/QmNtkhZgNGUW1jauvQ8SZ6b1Mkp68fshE33jkqizhJyoc5",
        "rarity": "Uncommon",
        "prediction": "A deer stands tall in the forest. As the deer is a tribe animal, this card is about the tribe, or the group. Humans are social creatures and we need to feel included and useful to our tribe: be it our family or our communities. We are  all different so a tribe will allow us to bring our diverse skills together and to accomplish more.  Membership to a group  provides us with a sense of belonging, kinship and safety. It can also provide us with a sense of purpose that comes from having other people counting on us. This card tells you that you are part of a community - be it family, spiritual community or something else, and it brings you value and joy. Even if your contribution is small, you are appreciated and needed. If your contribution is big and you are a leader, know that all of your hard work will be seen by other people."
    },
    "A22Clarity": {
        "name": "Clarity",
        "id": 130,
        "image": "https://gateway.pinata.cloud/ipfs/QmZnaovcZLxHm1WZXKQWHRgERNert3xk6BhUWEP7jKFwWR",
        "rarity": "Uncommon",
        "prediction": "The thunder strikes the tiger's head, the tiger seems to be focused and to have a clear vision. This card is about finding clarity. Sometimes we feel stuck, our brain is in fog, we can’t think straight, and we have no clear path or purpose. We are waiting for a moment of clarity. This card is about being fully focused on our goal, energetically, by thoughts, and by being fully immerse in what we are doing. This single focus - in which all of our energy, thoughts, emotions and actions are engaged and focused on the goal - will lead us to an \"aha\" moment of clarity and understanding. "
    },
    "A7Mystery": {
        "name": "Mystery",
        "id": 131,
        "image": "https://gateway.pinata.cloud/ipfs/QmYHscjTaJFU1ryWomBxvh73zpssa9Jt5RTAZSYR1fkjJC",
        "rarity": "Rare",
        "prediction": "This card is about the mysteries: it’s about something that we don't understand and that fascinates us. We live in a world with lots of available information. We can study and learn anything if we put enough efforts and time into it. But this card is about true mystery, and the understanding that's not intellectual. There are things we  understand only because we have  experienced it, any intellectual explanation won't make sense. Only the embodiment will help us to understand it: in our body, emotions and thoughts. We understand it because we lived through it. This card is about those experiences: you may go through challenges, or surprising events, but this card tells you that you are learning by going through those situations, by living and embodying life experience you will be able to touch the mysteries."
    },
    "A20Protector": {
        "name": "Protector",
        "id": 133,
        "image": "https://gateway.pinata.cloud/ipfs/QmdSMVZxkLaeuisvG8JJn3HKbaBjKxktJbQCZWw8m4mtVf",
        "rarity": "Common",
        "prediction": "This card is about your spirit animal, it can sound very \"new age\" But in cultures around the world people honored the unseen. And children - later adults, have unseen protectors: often ancestors, but also animal spirits or other entities linked to nature. One of the main rites of passage for the Lakota people for example is a vision quest in nature to find one's Spirit Animal. It’s not a Native American specific thing: some Christian  would consider their Guardian angels or favorite Saints as their unseen protectors. This card is about this entity, whatever name you want to give him/her. This card tells you that you are guided and protected. This  protector won't protect you against the consequences of your own choices & actions: this is how we truly learn. But he/her will protect you against destructive events and dangers that are out of your control."
    },
    "A34Sea": {
        "name": "Sea",
        "id": 133,
        "image": "https://gateway.pinata.cloud/ipfs/QmTLSUS19eQznP1ueT7dKqBBVF8vrikfc4BoLVz5zDcr3W",
        "rarity": "Epic",
        "prediction": "This card is about being in the flow of life, when events and conditions engage us in a way that is effortless and natural. This card reminds you that going with the flow is what you need right now. Whatever is happening, this card tells you that riding the wave of the events and opportunities is what you should do, and will yield the best outcome. Sometimes it's hard to trust the flow of events because we have no control over it and sometimes, we may think that we can't be successful if we don't work hard enough. This card tells you that it's fine to just ride the wave, life is full of opportunities triggered at the right moment: some people  call it luck. Enjoy yours."
    },
    "A33Balanced": {
        "name": "Balanced",
        "id": 134,
        "image": "https://gateway.pinata.cloud/ipfs/QmYz3hqxSozBpBCWrzgD6gtDXGbNEjZrxNGnteJT58PEZD",
        "rarity": "Common",
        "prediction": "This card reveals a time of harmony, balance and justice in your life. You may still have some balance to restore in your affairs: have you given too much or taken too much? If you received help in an important situation, did you help people back? If you have given to people, are you opened to receiving as well? Life is about giving and receiving, so we are always in balance. Without lack and surplus, we stand in the middle, as the middle of a scale, between giving and receiving. This card tells you to reflect about balance in your life and relationships. Often, it's easier to give than to receive, when we need help and receive it, we may ask ourselves \"does it mean I'm not independent and worthy?\". Often, it's also easier to tell ourselves that we've made it without help, forgetting all the people who extended their helping hands to us, we enjoy the self-made success stories. This card nudges us to rethink the giving/receiving dynamics and how it manifests in our lives."
    },
    "A29Road_Fork": {
        "name": "Road Fork",
        "id": 135,
        "image": "https://gateway.pinata.cloud/ipfs/Qmefyv2cGzmH98dPyCf5xWCm4XKqAHSRzu93V7aLdgKuph",
        "rarity": "Rare",
        "prediction": "This card tells you that you've arrived at a fork in the road and you are being asked to come to a decision. Often, we have the choice between a road less traveled and a road that's more reassuring and taken by other people before us. What choice will you make? This card tells you that there is no wrong or right choice. The level of risk you want to take is yours to decide. This card tells you to be fully present and to see all the aspects of each path. Instead of listening to other people, ask yourself: which path fits better in your life? Maybe you're at a moment in your life that doesn't fit well with taking risk, or maybe you want to experience something new. There is no wrong choice, only different experiences."
    },
    "A31Enlightening_The_Way": {
        "name": "Enlightening The Way",
        "id": 137,
        "image": "https://gateway.pinata.cloud/ipfs/QmQWcsgzPjfaGu74TMeQcBhGAEFn3DCsUL7Q2LDcbU9MSd",
        "rarity": "Common",
        "prediction": "A lighthouse stands in the middle of the light. This card is about enlightening the way in the dark. This card tells you that you are a good leader, and people will listen to you. We often think of ourselves as not being good enough, we tend to compare ourselves to other people, which is made easier by social medias. But you are enough. You are not good in everything, nobody is. So, there is no point to compare yourself in everything that you do to other people. But you have a specific set of skills: be it in your job, in a personal passion, or a quality like being good as listening and understanding people. And you will be able to light the way, help other people and be a visible figure using this set of skills. "
    },
    "A2Water_Cave": {
        "name": "Water Cave",
        "id": 138,
        "image": "https://gateway.pinata.cloud/ipfs/QmdQNVzotLRrfEwJaENTcyoJMHEzajGRRLVTWpWWKqvRHn",
        "rarity": "Common",
        "prediction": "A woman swims in the sea toward the light. This card is about emotions as the water element is linked to emotions: both are fluid and changing, but also linked to our deep instincts, everything that is not \"seen\" physically but is deeply felt in our body. This card is about seeing clearly our emotions. When we are honest with ourselves and how we feel, we are better armed to deal with our own emotions. For example, if we are anxious, we should ask ourselves what causes this feeling in our body? Can we feel the subtle change in body sensation? This card is about being brutally honest with ourselves. We have all the resources that we need to work with our own emotions, so we should acknowledge them."
    },
    "A34Deep_Unconsciousness": {
        "name": "Deep Unconsciousness",
        "id": 138,
        "image": "https://gateway.pinata.cloud/ipfs/QmQLQNUxtXeY8HdzWb2ibDqoYqZ7LcgeZKNbeQ9J6bSAhN",
        "rarity": "Uncommon",
        "prediction": "A woman swims in a water cave full of trees, following a dolphin up to the surface. This card is about our deep unconsciousness, the one that's conditioned by our fears, our instinct and the formative childhood years of our personalities. It's a cave full of events, emotions, thoughts that we no longer remember but that still condition us. It's not all bad: fear  protects us too, so we won't stay in dangerous situations, fear will nudge us to run away from it. But we want to ask ourselves if our fear, anxiety and other instinctive reactions actually serve us? Does our instinctive reaction  have a purpose when we feel it? Does it serve us in our daily life right now? This card invites us to become more conscious of our own deeper conditioning and instincts. This card is also about the ability to access and heal this deeper part of us, by beginning to be more aware of it and  listening to it."
    },
    "0Metamorphosis": {
        "name": "Metamorphosis",
        "id": 139,
        "image": "https://gateway.pinata.cloud/ipfs/QmWxvD65rxrnfJg2KnCXCpyg8WpFFcrBRCPkozEQNsYykS",
        "rarity": "Uncommon",
        "prediction": "The process of change is often sudden and painful, like a lightning strike. Change never happens without losses and sacrifices. If you are to transform from one form to another, a part of you needs to die to welcome your newer part. This card is about an in-between situation. Where you need to let go of a part of you that no longer serves you. It's hard, especially when we are used to think and act a certain way. But we need to release some old habits and idea to embrace a necessary change, and welcoming the new. Think about what is no longer necessary in your life that you could let go - be it a relationship, an unhealthy habit or a thought pattern that no longer serves you. You can do it and accept it's for the better good. This card tells you that you'll be happy with your change, and you're on the right path."
    },
    "A4Wisdom": {
        "name": "Wisdom",
        "id": 140,
        "image": "https://gateway.pinata.cloud/ipfs/QmetbLJXMcJb7QLrPT6kp4BjU4RjQVCfcts7jDD67FFEqk",
        "rarity": "Common",
        "prediction": "A man sits on a giant tree, with the moon above his head and the sky full of stars behind him. This card is about the deep wisdom we gain when we are connected with everything around us, be it nature or our environment. This card talks about the consciousness we put in doing what we . This card is about connecting with the task we have on hand and being fully focused on it.. From this space, it's easier to understand, when we connect beyond the intellectual level because we are fully in the pattern of what we are doing. This card is about being fully focus on what we are doing and engaging with. From this space, we will be able to yield more results and understanding."
    },
    "A1Light": {
        "name": "Light",
        "id": 141,
        "image": "https://gateway.pinata.cloud/ipfs/QmRcVdWhzeM8Y2iBve3HjvkbHdTDeRGwBtx1bs31ujsDAh",
        "rarity": "Uncommon",
        "prediction": "A bright balloon rises above the clouds, and the moon shines. This card is about the light that guides us. When we feel stuck, we tend to look at our immediate issues, our surroundings and the recent events in our lives. This card is about rising up and finding space. What lies beyond? When we rise our head above our daily thoughts, what do we see? We may see the moon shines from the above, hidden in the clouds, guiding us. This card tells you that the light is at the end of the tunnel, and you may want to look at the situation in an unusual way to find it. Everything is going to be fine."
    },
    "A22Kind_Presence": {
        "name": "Kind Presence",
        "id": 142,
        "image": "https://gateway.pinata.cloud/ipfs/Qmf2bsAZRaXJ4yAgUxab5n1AFwivAoUEnuggS5DmkhhWSh",
        "rarity": "Rare",
        "prediction": "A gentle Elephant stands in the night. This card is about a kind and reliable person in your life. Someone who is there for you and who reminds you that you're safe. Someone who has reassuring quality and integrity. Someone who also speaks word of wisdom under different forms: it could be religious, spiritual words or just everyday advice. Wisdom flows through their words: the person who speaks them is more important than the words themselves. As the wise person speaks wise words. This card tells you that you are supported and loved, if you need help you only need to ask. This card also tells you that you may have things to learn from a kind presence in your life."
    },
    "A35Slow_Moving": {
        "name": "Slow Moving",
        "id": 143,
        "image": "https://gateway.pinata.cloud/ipfs/QmSLJvaYccmjumfGyRwNzTcppJJsSvYJu84F483ohvZtHM",
        "rarity": "Uncommon",
        "prediction": "To bring your dreams to life you need to think long term, and not pay too much attention to the fluctuation in the current of your daily experience. Joy, disappointment and opportunities are all part of our daily experience. This card tells you to see the bigger picture and to never lose sight of your long-term goal. Life is not a sprint, and you have enough energy, endurance and resilience to go to the end. When everything gets confusing, and when you are confronted with a tricky situation remember that you are on the right path in the long term, and that everything will come to pass in the short term."
    },
    "A16Wind_Of_Change_2": {
        "id": 144,
        "rarity": "Epic",
    },
    "A3Forest_Spirit_2": {
        "id": 145,
        "rarity": "Epic",
    },
    "A28Discover_New_2": {
        "id": 146,
        "rarity": "Uncommon",
    },
    "A24Heal_The_Earth_2": {
        "id": 147,
        "rarity": "Uncommon",
    },
    "0Have_Faith_2": {
        "id": 148,
        "rarity": "Rare",
    },
    "A38Oasis_2": {
        "id": 149,
        "rarity": "Epic",
    },
    "0Ancient_Tree_2": {
        "id": 150,
        "rarity": "Rare",
    },
    "A17Fate_2": {
        "id": 151,
        "rarity": "Epic",
    },
    "A4Surrender_2": {
        "id": 152,
        "rarity": "Uncommon",
    },
    "0Metamorphosis_2": {
        "id": 153,
        "rarity": "Uncommon",
    },
    "A34Sea_2": {
        "id": 154,
        "rarity": "Epic",
    },
    "A4True_Magic_2": {
        "id": 155,
        "rarity": "Epic",
    },
    "0Dry_Desert_2": {
        "id": 156,
        "rarity": "Common",
    },
    "A22Clarity_2": {
        "id": 157,
        "rarity": "Uncommon",
    },
    "A9Strength_2": {
        "id": 158,
        "rarity": "Rare",
    },
    "0The_Journey_Signed": {
        "id": 159,
        "rarity": "Common",
    },
    "A35Slow_Moving_2": {
        "id": 160,
        "rarity": "Uncommon",
    },
    "A1Light_2": {
        "id": 161,
        "rarity": "Uncommon",
    },
    "A9Following_The_Leader_2": {
        "id": 162,
        "rarity": "Uncommon",
    }
}